import React, { useState, useRef, useContext, useEffect } from 'react';
import { TextField, Button, Typography, Box, IconButton, Stack, Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { v4 as uuid } from 'uuid';
import FacilityNotificationsAudience from './facility-notifications-audience.component';
import SendIcon from '@mui/icons-material/Send';
import MySportSpaceAlert from "../../alert/alert.component";
import { SendFacilityCustomEmail, CreateFacilityEmailTemplate, GetEmailTemplates, GetEmailTemplate } from '../../../utils/facility-api/facility-service';
import { UserContext } from "../../../contexts/user.context";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BottomButtonBar from '../../bottom-button-bar/bottom-button-bar.component';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import juice from 'juice';
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { base64ToFileWithoutMetadata, extractBetween, base64ToFile, findAllOccurrences } from '../../../utils/file-utilities/file-utilities';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined';
import FacilityCustomerSelectGroup from '../facility-customer-groups/facility-customer-select-group.component';
import QuillStyles from './facility-email-builder.quil.styles.component';



const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
const MAX_DOCUMENT_SIZE = 2 * 1024 * 1024; // 5MB in bytes

// Email templates
const emailTemplates = {
    basic: `
      <div style="background-color: #f4f4f4; padding: 10px;">
        <div style="background-color: white; padding: 10px; border-radius: 8px; margin: auto;">
            <div style="box-sizing:border-box;font-family:Helvetica,Arial,sans-serif;font-size:13px;width:100%;height:100%;margin:0;position:relative" class="ql-container ql-snow">
                <div class="ql-editor">
                    <p>{{body}}</p>
                </div>
             </div>
          <footer style="text-align: center; font-size: 12px; color: #aaa;">
            <p>&copy; My Sport Space is not responsible for the content of this email. </p>
          </footer>
        </div>
      </div>
    `,
    professional: `
      <div style="background-color: #e5e5e5; padding: 40px;">
        <div style="background-color: #ffffff; padding: 30px; border-radius: 10px; max-width: 600px; margin: auto;">
          <section style="font-size: 16px; line-height: 1.6; color: #333;">
            {{body}}
          </section>
          <footer style="text-align: center; font-size: 14px; color: #888; margin-top: 30px;">
            <p>If you have any questions, feel free to contact us at support@mycompany.com</p>
            <p>&copy; 2024 MyCompany</p>
          </footer>
        </div>
      </div>
    `,
    modern: `
      <div style="font-family: Arial, sans-serif; background-color: #fafafa; padding: 30px;">
        <div style="max-width: 650px; margin: auto; background-color: #ffffff; padding: 40px; border-radius: 8px;">
          <div style="font-size: 16px; color: #555; line-height: 1.5;">
            {{body}}
          </div>
          <footer style="text-align: center; font-size: 14px; color: #777; margin-top: 40px;">
            <p>&copy; 2024 MyCompany. Stay connected!</p>
          </footer>
        </div>
      </div>
    `,
};

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
};

const FacilityEmailBuilderQuil = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [subject, setSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [imageUrls, setImageUrls] = useState([]);
    const [imageSizes, setImageSizes] = useState([]); // Store image sizes (width, height)
    const [selectedTemplate, setSelectedTemplate] = useState('basic');
    const [emailPreview, setEmailPreview] = useState(emailTemplates.basic)
    const [emailFileAttachments, setEmailFileAttachments] = useState([]);
    const [selectedAudience, setSelectedAudience] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [sendPromptMessage, setSendPromptMessage] = useState(null);
    const { userData } = useContext(UserContext);
    const [successDialogMessage, setSuccesDialogMessage] = useState(false);
    const [customEmailTemplates, setCustomEmailTemplates] = useState([]);
    const [emailTemplateAnchorEl, setEmailTemplatesAnchorEl] = useState(null);
    const [emailAddresses, setEmailAddresses] = useState(null);
    const [emailAddressErrors, setEmailAddressErrors] = useState([]);
    const [customerGroups, setCustomerGroups] = useState([]);
    const [fromName, setFromName] = useState("");
    const [replyTo, setReplyTo] = useState("");

    const quillRef = useRef(null); // Reference to the editor instance

    const fontSizeArray = [
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '22px',
        '24px',
        '26px',
        '28px',
        '36px',
        '48px',
    ];
    const fontSizeStyle = Quill.import('attributors/style/size');
    fontSizeStyle.whitelist = fontSizeArray;
    Quill.register(fontSizeStyle, true);


    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'size': fontSizeArray }],
        [{ 'align': [] }],

    ];

    const removeWhitespace = (str) => `${str}`.replace(/\s+/g, '');

    // Render email preview with the selected template
    const renderEmailPreview = async () => {
        const generatedHtml = await generateEmailHtml(true);
        setEmailPreview(generatedHtml)
    };
    const generateEmailHtml = async (convertEmailStyle) => {

        const template = emailTemplates[selectedTemplate];
        const templateBody = template.replace('{{body}}', emailBody);

        if (!convertEmailStyle) {
            const templateHtmlDoc = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
            </head>
            <body>
            <div>
              ${templateBody}
            </div>
            </body>
            </html>
          `;

            return templateHtmlDoc;
        } else {
            const convertedHtml = await convertHtmlToInlineStyles(templateBody);
            const htmlDoc = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
            </head>
            <body>
            <div>
              ${convertedHtml}
            </div>
            </body>
            </html>
          `;

            return htmlDoc;
        }

    }

    useEffect(() => {
        renderEmailPreview();
    }, [emailBody]); // Re-run if rawHtml changes

    const retrieveEmailTemplates = async (facilityId) => {
        if (!isLoading) {
            setIsLoading(true);
        }

        const response = await GetEmailTemplates(facilityId);
        if (response && response.data && response.data.status === 'success') {
            setCustomEmailTemplates(response.data.emailTemplates);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (userData) {
            retrieveEmailTemplates(userData.facilityId);
            setFromName(userData.organizationName);
            setReplyTo(userData.userEmailAddress)
        }

    }, [userData])

    const handleEmailAddressInputChange = (event) => {
        const input = event.target.value;

        // Split emails by commas, newlines, or spaces
        const emailList = input.split(/[\s,]+/);

        // Validate each email
        const newErrors = emailList.map((email) => {
            if (email && !isValidEmail(email)) {
                return `Invalid email: ${email}`;
            }
            return null;
        });

        setEmailAddressErrors(newErrors.filter((error) => error !== null));

        // Limit to 50 emails
        if (emailList.length <= 50) {
            setEmailAddresses(input);
        } else {
            setAlertMessage("You can only enter up to 50 email addresses.");
        }
    };

    const handleAddEmailFileClick = (event) => {

        const files = event.target.files;
        const validFiles = Array.from(files).filter(file => file.size <= MAX_DOCUMENT_SIZE);

        if (validFiles.length !== files.length) {
            alert("Some documents exceeded the max file size of 5MB and have been excluded.");
        }

        const newFiles = Array.from(validFiles);
        for (const file of newFiles) {
            file.source = 'user'
            file.id = uuid();
        }
        setEmailFileAttachments(emailFileAttachments.concat(newFiles));
    }

    const deleteEmailFileAttachment = (e) => {
        const fileId = e.currentTarget.dataset.id;
        setEmailFileAttachments(emailFileAttachments.filter(file => file.id !== fileId));
    }

    const viewEmailFileAttachment = (e) => {
        const fileId = e.currentTarget.dataset.id;
        const existingEmailFileAttachments = emailFileAttachments.filter(file => file.id === fileId);
        if (existingEmailFileAttachments.length > 0) {
            const emailAttachment = existingEmailFileAttachments[0];
            const fileURL = URL.createObjectURL(emailAttachment);
            if (fileURL) {
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = emailAttachment.name; // specify the filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    // Handle subject change
    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleFromNameChange = (e) => {
        setFromName(e.target.value);
    }

    const handleReplyToChange = (e) => {
        setReplyTo(e.target.value);
    }

    // Handle image upload
    const handleImageUpload = (e) => {
        const files = e.target.files;
        setupImages(files);
    };

    const setupImages = (files) => {
        const newImageUrls = [];
        const newImageSizes = [];
        const validFiles = Array.from(files).filter(file => file.size <= MAX_FILE_SIZE);
        if (validFiles.length !== files.length) {
            alert("Some files exceeded the max file size of 5MB and have been excluded.");
        }

        Array.from(validFiles).forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                newImageUrls.push(reader.result); // Base64 image URL
                newImageSizes.push({ width: 150, height: 150 }); // Initial size of the image
                if (newImageUrls.length === validFiles.length) {
                    setImageUrls((prevImages) => [...prevImages, ...newImageUrls]);
                    setImageSizes((prevSizes) => [...prevSizes, ...newImageSizes]);
                }
            };
            reader.readAsDataURL(file);
        });
    }

    // Insert image at the cursor position in the email body
    const insertImageAtCursor = (url, imageIndex) => {

        const imageWidth = imageSizes[imageIndex].width;
        const imageHeight = imageSizes[imageIndex].height;

        convertImageToDataURL(url, imageWidth, imageHeight).then((dataURL) => {
            const editor = quillRef.current.getEditor(); // Access the Quill editor instance
            const range = editor.getSelection(); // Get the current selection
            if (range) {
                editor.insertEmbed(range.index, 'image', dataURL); // Insert the image
            }
        });
    };

    const convertImageToDataURL = async (url, imageWidth, imageHeight) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'anonymous'; // Allow cross-origin images to be processed
            img.src = url;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = imageWidth;
                canvas.height = imageHeight;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, imageWidth, imageHeight);

                // Convert canvas content to Data URL
                const dataURL = canvas.toDataURL('image/png');
                resolve(dataURL);
            };

            img.onerror = (err) => {
                reject(new Error('Failed to load the image: ' + err));
            };
        });
    };

    const enlargeImage = (e, imageIndex) => {
        const newWidth = imageSizes[imageIndex].width + 10;
        const newHeight = imageSizes[imageIndex].height + 10;

        if (newWidth > 600 || newHeight > 800) {
            setAlertMessage("Images cannot be larger than 600 X 800")
            return
        }

        // Only allow resizing if width and height are greater than 20px (adjust as necessary)
        if (newWidth > 20 && newHeight > 20) {
            setImageSizes((prevSizes) => {
                const updatedSizes = [...prevSizes];
                updatedSizes[imageIndex] = {
                    width: newWidth,
                    height: newHeight,
                };
                return updatedSizes;
            });
        }
    };

    const reduceImage = (e, imageIndex) => {
        const newWidth = imageSizes[imageIndex].width - 10;
        const newHeight = imageSizes[imageIndex].height - 10;

        if (newWidth < 20 || newHeight < 20) {
            setAlertMessage("Images cannot be small than 20 X 20")
            return
        }

        // Only allow resizing if width and height are greater than 20px (adjust as necessary)
        if (newWidth > 20 && newHeight > 20) {
            setImageSizes((prevSizes) => {
                const updatedSizes = [...prevSizes];
                updatedSizes[imageIndex] = {
                    width: newWidth,
                    height: newHeight,
                };
                return updatedSizes;
            });
        }
    };

    // Remove image from the list of images and update the state
    const handleRemoveImage = (index) => {
        const newImageUrls = [...imageUrls];
        const newImageSizes = [...imageSizes];

        newImageUrls.splice(index, 1);
        newImageSizes.splice(index, 1);

        setImageUrls(newImageUrls);
        setImageSizes(newImageSizes);

        // Also update the emailBody state to reflect image removal
        setEmailBody((prevBody) => {
            const updatedBody = prevBody.replace(`<img src="${imageUrls[index]}" style="width:150px;height:150px;">`, '');
            return updatedBody;
        });
    };

    const convertHtmlToInlineStyles = async (html) => {
        try {
            // Fetch the Quill stylesheet
            const stylesheetUrl = "https://cdn.jsdelivr.net/npm/quill@2.0.3/dist/quill.snow.css";
            const response = await fetch(stylesheetUrl);
            const stylesheet = await response.text();

            // Use juice to inline the styles
            const inlinedHtml = juice.inlineContent(html, stylesheet);
            return inlinedHtml;
        } catch (error) {
            console.error("Error converting to inline styles:", error);
            return html;
        }
    };


    const selectTheAudience = (audience) => {
        setEmailAddresses(null);
        setCustomerGroups([]);
        setSelectedAudience(audience);
    }
    const closeAlert = () => {
        setAlertMessage(null);
    }

    const closeSuccessDialog = () => {
        setSuccesDialogMessage(null);
    }

    const handleSendEmail = (e) => {
        setSendPromptMessage(null);

        if (emailBody.length <= 0) {
            setAlertMessage('You must provide an email body');
            return;
        }

        if (subject.length <= 0) {
            setAlertMessage('You must provide an email subject');
            return;
        }

        if (!selectedAudience || selectTheAudience.length <= 0) {
            setAlertMessage('You must provide an audience');
            return;
        }

        if (selectedAudience === 'user_input' && (!emailAddresses || emailAddresses.length <= 0)) {
            setAlertMessage('You must provide at least 1 email address');
            return;
        }

        if (selectedAudience === 'customer_groups' && (!customerGroups || customerGroups.length <= 0)) {
            setAlertMessage('You must provide at least 1 customer group');
            return;
        }

        if (!fromName || replyTo.fromName <= 0) {
            setAlertMessage('You must provide a reply to from name');
            return;
        }

        if (!replyTo || replyTo.length <= 0) {
            setAlertMessage('You must provide a reply to email address');
            return;
        }

        sendTheEmail();
    }

    const promptToSendEmail = (e) => {
        setSendPromptMessage("Are you sure you want to send the email?");
    }

    const closeSendPrompt = () => {
        setSendPromptMessage(null);
    }

    const extractValidEmails = () => {
        if (emailAddresses && emailAddresses.length > 0) {
            const emailList = emailAddresses.split(/[\s,]+/).filter(isValidEmail);
            return emailList;
        } else {
            return null;
        }
    }

    const sendTheEmail = async () => {

        setIsLoading(true);
        const html = await generateEmailHtml(true);

        //add sending the user inputed email addresses
        const validEmails = extractValidEmails();
        if (validEmails && validEmails.length > 100) {
            setAlertMessage("There is a maximum of 100 email addresses on sending with user input.")
            return;
        }

        const response = await SendFacilityCustomEmail(subject, html, userData.facilityId, selectedAudience, emailFileAttachments, validEmails, customerGroups, fromName, replyTo);
        if (response && response.data && response.data.status === 'success') {
            let message = 'The email was sent to your audience.'
            if (response.data.message) {
                message = response.data.message;
            }
            setSuccesDialogMessage(message);
        } else {
            setAlertMessage("Something went wrong and your email was not sent");
        }
        setIsLoading(false);
    }

    const saveAsTemplate = async () => {
        setAlertMessage(null);
        setIsLoading(true);

        const html = await generateEmailHtml(false);

        const response = await CreateFacilityEmailTemplate(subject, html, userData.facilityId, selectedAudience, emailFileAttachments);
        if (response && response.data && response.data.status === 'success') {

            if (userData) {
                await retrieveEmailTemplates(userData.facilityId);
            }

            setAlertMessage("The template has been saved");

        } else {
            setIsLoading(false);
            setAlertMessage("Something went wrong and your email template was not saved");
        }
    }

    const showTemplates = (e) => {
        setEmailTemplatesAnchorEl(e.currentTarget);
    }

    const closeEmailTemplates = () => {
        setEmailTemplatesAnchorEl(null);
    }

    const resetState = () => {
        setCustomerGroups([]);
        setSelectedAudience('')
        setEmailAddresses(null);
        setEmailBody('');
        setEmailFileAttachments([]);
        setSubject('');
        setEmailTemplatesAnchorEl(null);
        setImageSizes([])
        setImageUrls([]);
    }

    const useTemplate = async (e) => {
        const templateId = e.currentTarget.dataset.id;
        setIsLoading(true);
        resetState();
        const response = await GetEmailTemplate(templateId);
        if (response && response.data && response.data.status === 'success') {
            const emailTemplate = response.data.emailTemplate;
            setEmailBody(emailTemplate.html);
            setSubject(emailTemplate.subject);

            const templateImages = [];
            if (emailTemplate.html) {
                let index = 1;
                const allImages = findAllOccurrences(emailTemplate.html, '<img src="', '"')
                for (const imageStr of allImages) {
                    const imageData = extractBetween(imageStr, '<img src="', '"');
                    const image = base64ToFile(imageData, `${templateId}_${index}`);
                    templateImages.push(image);

                    index += 1;
                }
                setupImages(templateImages);
            }

            if (emailTemplate.attachments && emailTemplate.attachments.length > 0) {

                const templateAttachments = [];
                for (const attachment of emailTemplate.attachments) {
                    const newAttachment = base64ToFileWithoutMetadata(attachment.content, attachment.filename, attachment.type);
                    templateAttachments.push(newAttachment);

                }
                setEmailFileAttachments(templateAttachments);
            }
        } else {
            setAlertMessage("The email template could not be found.");
        }
        setIsLoading(false);
    }

    const customerGroupsWereSelected = (groups) => {
        setCustomerGroups(groups)
    }

    const openTemplates = Boolean(emailTemplateAnchorEl);
    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} sx={{ padding: '20px', margin: 'auto', fontFamily: 'Arial, sans-serif' }}>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={!isLoading && alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={!isLoading && sendPromptMessage ? true : false} message={sendPromptMessage} okButtonText={'Yes'} okAction={handleSendEmail} cancelButtonText={'No'} cancelAction={closeSendPrompt} ></MySportSpaceAlert>
            <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontWeight={'bold'} fontSize={'1.75vw'} gutterBottom>Create and Send Email</Typography>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} columnGap={1} >
                    <Button size='small' variant="contained" onClick={(e) => {
                        promptToSendEmail(e);
                    }} endIcon={<SendIcon />}>
                        Send Email
                    </Button>
                    <Button size='small' variant="contained" onClick={(e) => {
                        saveAsTemplate(e);
                    }} endIcon={<SaveOutlinedIcon />}>
                        Save As Template
                    </Button>
                    <Button size='small' variant="contained" onClick={(e) => {
                        showTemplates(e);
                    }} endIcon={<DownloadOutlinedIcon />}>
                        Use Template
                    </Button>
                    <Menu
                        id="email-template-menu"
                        anchorEl={emailTemplateAnchorEl}
                        open={openTemplates}
                        onClose={closeEmailTemplates}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {
                            customEmailTemplates.map((template) => {
                                return <MenuItem onClick={useTemplate.bind(this)} key={template.id} data-id={template.id}>{template.name}</MenuItem>
                            })
                        }
                    </Menu>
                    <Button size='small' variant="contained" onClick={(e) => {
                        resetState(e);
                    }} endIcon={<PhonelinkEraseOutlinedIcon />}>
                        Clear All
                    </Button>
                </Box>
            </Stack>

            <FacilityNotificationsAudience audience={selectedAudience} selectAudience={selectTheAudience} />
            {
                selectedAudience === 'user_input' &&
                <Stack mb={2}>
                    <Typography mb={1} fontWeight={'bold'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.25vw' }}>Enter Email Addresses</Typography>
                    <TextField
                        label="Email Addresses"
                        multiline
                        rows={5}
                        fullWidth
                        value={emailAddresses}
                        onChange={handleEmailAddressInputChange}
                        helperText={`Enter up to 50 email addresses, separated by commas or new lines.`}
                        error={emailAddressErrors.length > 0}
                    />
                    {emailAddressErrors.length > 0 && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                            {emailAddressErrors.map((error, index) => (
                                <Typography key={index} variant="body2">
                                    {error}
                                </Typography>
                            ))}
                        </div>
                    )}
                </Stack>
            }
            {
                selectedAudience === 'customer_groups' &&
                <FacilityCustomerSelectGroup selected={customerGroups} selectGroups={customerGroupsWereSelected}></FacilityCustomerSelectGroup>
            }
            <TextField
                label="From Name"
                value={fromName}
                onChange={handleFromNameChange}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: 2 }}
                helperText={"The name the customer will see this email is from"}
            />
            <TextField
                label="Reply To"
                value={replyTo}
                onChange={handleReplyToChange}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: 2 }}
                helperText={"The email address any replies to the email will go to"}
            />

            {/* Subject */}
            <TextField
                label="Subject"
                value={subject}
                onChange={handleSubjectChange}
                fullWidth
                variant="outlined"
                sx={{ marginBottom: 2 }}
            />
            <QuillStyles />
            <Stack mb={5} spacing={1} justifyContent={'stretch'} alignItems={'stretch'} >
                <Typography fontWeight={'bold'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.25vw' }}>Email Body</Typography>
                <ReactQuill
                    ref={quillRef}
                    value={emailBody}
                    onChange={(value) => setEmailBody(value)}
                    modules={{
                        toolbar: toolbarOptions
                    }
                    }
                    theme='snow'
                />
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2, position: "relative" }}>
                <input
                    id="file-upload"
                    type="file"
                    onChange={handleImageUpload}
                    accept="image/png, image/jpeg, image/jpg"
                    multiple
                    style={{
                        opacity: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 2,
                        cursor: "pointer",
                    }}
                />
                <Button
                    variant="outlined"
                    sx={{
                        height: "56px", // Matches default TextField height
                        justifyContent: "center",
                    }}
                    component="span"
                >
                    Click To Upload Image
                </Button>
            </Box>

            {/* Image Insertion and Resizing */}
            <Box sx={{ marginBottom: 2 }}>
                <Stack>
                    <Typography fontWeight={'bold'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.25vw' }}>Uploaded Images</Typography>
                    {
                        imageUrls.length > 0 &&
                        <Typography ml={2} color={"darkgray"} fontSize={{ xs: '1.25vw', sm: '1.25vw', md: '1.0vw' }}>After uploading the image, click on the image below to add to the message</Typography>
                    }
                </Stack>

                <Box sx={{ minHeight: '150px', display: 'flex', flexWrap: 'wrap', marginTop: 1, borderWidth: '1px', borderColor: '#DEDEDE', borderRadius: '5px', borderStyle: 'solid' }}>
                    {imageUrls.map((url, index) => (
                        <Box key={index} sx={{ position: 'relative', margin: 1 }}>
                            <img
                                src={url}
                                alt={`Uploaded image ${index}`}
                                style={{
                                    width: `${imageSizes[index].width}px`,
                                    height: `${imageSizes[index].height}px`,
                                    cursor: 'pointer',
                                    objectFit: 'contain',
                                }}
                                onClick={() => insertImageAtCursor(url, index)}
                            />
                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                columnGap: '5px',
                            }}>
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={(e) => enlargeImage(e, index)}
                                >
                                    <AddOutlinedIcon></AddOutlinedIcon>
                                </IconButton>
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={(e) => reduceImage(e, index)}
                                >
                                    <RemoveOutlinedIcon></RemoveOutlinedIcon>
                                </IconButton>
                            </Box>
                            <IconButton
                                variant="contained"
                                size="small"
                                color="error"
                                sx={{
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-15px',
                                    zIndex: 1,
                                }}
                                onClick={() => handleRemoveImage(index)}
                            >
                                <DeleteForeverIcon></DeleteForeverIcon>
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'} mb={5}>
                {/* <Box width={'100%'} height={'100%'} display={'flex'} justifyItems={'stretch'} alignItems={'stretch'} flexDirection={'column'}> */}
                <Stack display={'flex'} direction={'row'} alignItems={'center'} >
                    <Typography fontWeight={'bold'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.25vw' }}>Attachments</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', position: "relative" }}>
                        <input
                            id="attachment-upload"
                            type="file"
                            onChange={handleAddEmailFileClick}
                            accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt"
                            multiple
                            style={{
                                opacity: 0,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 2,
                                cursor: "pointer",
                            }}
                        />
                        <Button
                            startIcon={<AttachFileOutlinedIcon />}
                            sx={{
                                justifyContent: "center",
                            }}
                            component="label"
                        >
                        </Button>
                    </Box>
                </Stack>
                <Box minHeight={'150px'} width={'100%'} display={'flex'} flexDirection={'column'} sx={{ borderWidth: '1px', borderColor: '#DEDEDE', borderRadius: '5px', borderStyle: 'solid' }}>
                    <Stack width={'100%'} mt={2} display={'flex'} direction={'row'} alignItems={'stretch'} justifyContent={'stretch'}>
                        {
                            emailFileAttachments.map((field) => {
                                const { name, id, source } = field;
                                return <Stack width={'100%'} key={`file-stack-${id}`} spacing={1} direction={'row'}>
                                    <TextField key={`file-field-${id}`} InputProps={{ readOnly: true }} fullWidth id={id} variant="outlined" label={'File Name'} type="text" name="text" value={name}></TextField>
                                    <IconButton key={`downlaod-view-btn-${id}`} edge="end" aria-label="downlaod-file" onClick={viewEmailFileAttachment.bind(this)} data-id={id}>
                                        <CloudDownloadOutlinedIcon key={`downlaod-view-icon-${id}`} />
                                    </IconButton>
                                    <IconButton key={`file-del-btn-${id}`} edge="end" aria-label="delete" onClick={deleteEmailFileAttachment.bind(this)} data-id={id}>
                                        <DeleteIcon key={`file-del-icon-${id}`} />
                                    </IconButton>
                                </Stack>
                            })
                        }
                    </Stack>
                </Box>
                {/* </Box> */}
            </Box>

            {/* Template Selector skip using this for now */}
            {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Select Template</InputLabel>
                <Select
                    value={selectedTemplate}
                    onChange={(e) => setSelectedTemplate(e.target.value)}
                    label="Select Template"
                >
                    <MenuItem value="basic">Basic</MenuItem>
                    <MenuItem value="professional">Professional</MenuItem>
                    <MenuItem value="modern">Modern</MenuItem>
                </Select>
            </FormControl> */}

            {/* Render Email Preview */}
            <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontWeight={'bold'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.25vw' }}>Email Preview</Typography>
                    <Button size='small' variant="contained" onClick={(e) => {
                        promptToSendEmail(e);
                    }} endIcon={<SendIcon />}>
                        Send Email
                    </Button>
                </Stack>

                <Box sx={{ border: '1px solid #ccc', padding: 2, backgroundColor: '#f9f9f9' }}>
                    <div dangerouslySetInnerHTML={{ __html: emailPreview }} />
                </Box>
            </Box>
            <Dialog
                open={successDialogMessage ? true : false}
                aria-labelledby="email-success-dialog-title"
                aria-describedby="email-success-dialog-description"
            >
                <DialogTitle id="email-success-dialog-title">
                    Email Sent
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="email-sucess-dialog-text">
                        {successDialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <BottomButtonBar okButtonText={'Save As Template'} okAction={saveAsTemplate} cancelButtonText={'OK'} cancelAction={closeSuccessDialog}></BottomButtonBar>
                </DialogActions>

            </Dialog>
        </Box>
    );
};

export default FacilityEmailBuilderQuil;
