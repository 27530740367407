import LoadingIndicator from '../../loading-indicator/loading-indicator.component';
import { Fragment, useContext, useState, useEffect } from 'react';
import CustomerList from '../../customer-list/customer-list.component';
import MembershipList from '../../membership-list/membership-list.component';
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import ViewReservationHeader from './view-reservation-header-component';
import { removeBlackout } from '../../../utils/facility-api/facility-service';
import MySportSpaceAlert from '../../alert/alert.component';
import { Box, Stack, Typography, TextField } from '@mui/material';
import { saveReservationEdits } from '../../../utils/firebase/reservation-firebase-utils';
import { getBlackoutReservedFor } from '../../../utils/reservation-api/reservation-service';

const ViewBlackout = ({reservation, close}) => {

  //  const { reservation, setReservationToView } = useContext(FacilityReservationsContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [removeMessage, setRemoveMessage] = useState(null);
    const [removedMessage, setRemovedMessage] = useState(null);
    const [cancelReservationActions] = useState(['Remove Blackout']);
    const [editReservationActions] = useState(["Edit Notes"]);
    const [reservationNotes, setReservationNotes] = useState(reservation.notes);
    const [isEditMode, setIsEditMode] = useState(false);
    const [reservedForMemberships, setReservedForMemberships] = useState([]);
    const [reservedForCustomers, setReservedForCustomers] = useState([]);

    const setupReservationNotes = (notes) => {
        setReservationNotes(notes);
    }

    const getReservedForLists = async (reservation) => {
        setIsLoading(true);

        const response = await getBlackoutReservedFor(reservation.facilityId, reservation.dateKey, reservation.reservationId);
        if (response && response.data && response.data.status === 'success') {
            const { reservedForCustomersList = [], reservedForMembershipsList = [] } = response.data;
            setReservedForMemberships(reservedForMembershipsList);
            setReservedForCustomers(reservedForCustomersList);
        } else {
            setReservedForMemberships([]);
            setReservedForCustomers([]);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        return () => {
            if (reservation) {
              //  setupReservationNotes(reservation.notes ? reservation.notes : "");
                getReservedForLists(reservation);
            }
        }
    }, [reservation]);

    const closeView = () => {
        if (close) {
            close();
        }
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const showAlertMessage = (message) => {
        setAlertMessage(message);
    }

    const showRemoveMessage = (text) => {
        setRemoveMessage(text);
    }

    const closeRemoveMessage = () => {
        setRemoveMessage(null)
    }

    const showRemovedMessage = (text) => {
        setRemovedMessage(text);
    }

    const actionSelected = async (action) => {

        switch (action) {
            case 'Remove Blackout':
                showRemoveMessage("Are you sure you want to remove this blackout?")
                break;
            case 'Edit Notes':
                setIsEditMode(true);
                break;
            case 'save':
                setIsEditMode(false);
                saveReservationNotes();
                break;
            case 'cancel':
                setIsEditMode(false);
                setReservationNotes(reservation.notes ? reservation.notes : '');
                break;
            default:
                console.log("Error!");
        }

    }

    const removeTheBlackout = async () => {
        setRemoveMessage(null);
        setIsLoading(true);
        const response = await removeBlackout(reservation);
        const { status, error } = response.data;
        if (status === 'success') {
            showRemovedMessage('The blackout has been removed.');
        } else {
            if (error) {
                showAlertMessage(error);
            } else {
                showAlertMessage('The blackout was not removed.');
            }
        }
        setIsLoading(false)
    }

    const handleChange = (event) => {
        const { value } = event.target;
        setReservationNotes(value);
    };

    const saveReservationNotes = async () => {
        setIsLoading(true);
        const notesSaved = await saveReservationEdits(reservation.facilityId, reservation.customerId, reservation.dateKey, reservation.reservationId, reservationNotes, null, null);
        if (notesSaved) {
            setAlertMessage("The reservation notes have been saved.");
            reservation.notes = reservationNotes;
        } else {
            setAlertMessage("The reservation notes were NOT saved.");
        }
        setIsLoading(false);
    }

    return (
        <Fragment>
            <div className='header-and-details'>
                {isLoading &&
                    <LoadingIndicator></LoadingIndicator>
                }
                {
                    alertMessage &&
                    <MySportSpaceAlert message={alertMessage} okButtonText={'OK'} okAction={closeAlert}></MySportSpaceAlert>
                }
                {
                    removeMessage &&
                    <MySportSpaceAlert message={removeMessage} okButtonText={'Remove'} okAction={removeTheBlackout} cancelButtonText={'DO NOT REMOVE'} cancelAction={closeRemoveMessage}></MySportSpaceAlert>
                }
                {
                    removedMessage &&
                    <MySportSpaceAlert message={removedMessage} okButtonText={'OK'} okAction={closeView} ></MySportSpaceAlert>
                }
                {
                    !isLoading &&
                    <Fragment>
                        <ViewReservationHeader isBlackout={true} isEditing={isEditMode} reservation={reservation} cancelReservationActions={!isEditMode ? cancelReservationActions : []} editReservationActions={!isEditMode ? editReservationActions : []} title={"Blackout Details"} closeAction={closeView} actionSelected={actionSelected} ></ViewReservationHeader>
                        <Box mt={2} ml={5} mr={5} mb={2} display={'flex'} flexDirection={'column'} rowGap={2}>
                            <Stack spacing={0}>
                                <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.75vw', lg: '1.0vw', xl: '1.0vw' } }}>Area</Typography>
                                <Box ml={3}>
                                    <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{reservation.mainAreaName}</Typography>
                                </Box>
                            </Stack>
                            <Stack direction={'row'} spacing={{xs: 1, sm: 1, md: 15}}>
                                <Stack spacing={0}>
                                    <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Date</Typography>
                                    <Box ml={3}>
                                        <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{reservation.resStartDate}</Typography>
                                    </Box>
                                </Stack>
                                <Stack spacing={0}>
                                    <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Time</Typography>
                                    <Box ml={3}>
                                        <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw',  md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{`${reservation.resStartTime} - ${reservation.resEndTime}`}</Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            <Stack spacing={0}>
                                <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Reason</Typography>
                                <Box ml={3}>
                                    <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{`${reservation.title} - ${reservation.subTitle}`}</Typography>
                                </Box>
                            </Stack>
                            <Box>
                                {reservedForCustomers.length <= 0 &&
                                    <Stack spacing={0}>
                                        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Reserved For Customers</Typography>
                                        <Box ml={3}>
                                            <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{'Not Applicable'}</Typography>
                                        </Box>
                                    </Stack>
                                }
                                {reservedForCustomers.length > 0 &&
                                    <Stack>
                                        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Reserved For Customers</Typography>
                                        <Stack ml={3} direction={'row'}>
                                            <CustomerList showHeader={false} customers={reservedForCustomers} isEditable={false}></CustomerList>
                                        </Stack>
                                    </Stack>
                                }
                            </Box>
                            <Box>
                                {reservedForMemberships.length <= 0 &&
                                    <Stack spacing={0}>
                                        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Reserved For Memberships</Typography>
                                        <Box ml={3}>
                                            <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{'Not Applicable'}</Typography>
                                        </Box>
                                    </Stack>
                                }
                                {reservedForMemberships.length > 0 &&
                                    <Stack>
                                        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Reserved For Memberships</Typography>
                                        <Stack ml={3} direction={'row'}>
                                            <MembershipList showHeader={false}  memberships={reservedForMemberships} isEditable={false}></MembershipList>
                                        </Stack>
                                    </Stack>
                                }
                            </Box>
                            <Stack mt={isEditMode ? 1 : 0}>
                                {
                                    isEditMode &&
                                    <TextField sx={{
                                        "& .MuiInputBase-input": {
                                            fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' }, // Change this to your desired font size
                                        },
                                      }}  multiline InputProps={{ readOnly: !isEditMode, fontSize: '1.0vw' }} fullWidth id="notes" variant={"outlined"} label="Notes" type="text" name="reservationNotes" value={reservationNotes} onChange={handleChange} />
                                }
                                {
                                    !isEditMode &&
                                    <Stack>
                                        <Typography fontWeight={'bold'} sx={{ fontSize: { xs: '3.0vw', sm: '3.0vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>Notes</Typography>
                                        <Stack ml={3} direction={'row'}>
                                            <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{reservationNotes}</Typography>
                                        </Stack>
                                    </Stack>
                                }
                            </Stack>
                        </Box>
                    </Fragment>
                }
            </div>
        </Fragment>
    )
}

export default ViewBlackout