import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/material';

const CustomerList = ({ customers, selectCustomer, removeCustomer, closeList, goUp, isEditable = false, header = 'Customers', showHeader = true }) => {

    const add = (e) => {
        selectCustomer();
    }

    const remove = (e) => {
        // removeCustomer(e.target.dataset.id);
    }

    let bottom = '1';
    if (goUp) {
        bottom = '0%';
    }

    return (
        <Box>
            {
                showHeader &&
                <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                    {header}
                </Typography>
            }
            <Stack>
                <Stack direction={'row'} overflow={'hidden'} flexWrap={'wrap'} rowGap={1} columnGap={1} >
                    {
                        customers.map(customer => (
                            <Box key={`box-${customer.stripeCustomerId}`} display={'flex'} >
                                <Stack key={`stack-${customer.stripeCustomerId}`} ml={2} spacing={1} direction={'row'} padding={'0.25vw'} alignItems={'center'} border={'solid'} borderRadius={'5px'} borderColor={'#DEDEDE'}>
                                    <Typography key={`name-${customer.stripeCustomerId}`} sx={{fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{`${customer.userFirstName} ${customer.userLastName}`}</Typography>
                                    {
                                        isEditable &&
                                        <Button key={`remove-btn-${customer.stripeCustomerId}`} variant='contained' sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '0.75vw' } }} aria-label={customer.stripeCustomerId} onClick={remove.bind(this)}>
                                            Remove
                                        </Button>
                                    }

                                </Stack>
                            </Box>
                        ))
                    }
                </Stack>
                {isEditable &&
                    <Button sx={{ fontWeight: 'bold' }} onClick={add.bind(this)} data-id={'customer-add'}>Add Customer</Button>
                }
            </Stack>
        </Box>
    )
}

export default CustomerList;