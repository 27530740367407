import userprofileImg from "../../../assets/user-profile.svg";
import { useState, useContext, useEffect, Fragment } from 'react';
import { UserContext } from '../../../contexts/user.context';
import AdditionalEmailRow from './additionalEmailRow.componet';
import FacilityViewCustomerReservations from '../facility-view-customer-reservations/facility-view-customer-reservations.component';
import { Button, Divider, Stack, Typography, FormControlLabel, Checkbox, IconButton, Modal, Box, Tabs, Tab, Avatar, Badge } from '@mui/material';
import FacilityInvoiceCustomersList from '../facility-invoice-customers-list/facility-invoice-customers-list.component';
import { updateCustomerInvoiceStatus, sendCustomerInvoiceInvoiceEmail } from '../../../utils/facility-api/facility-service';
import MySportSpaceAlert from '../../alert/alert.component';
import { saveFacilityCustomer } from '../../../utils/firebase/facility-firebase-utils';
import validator from 'validator';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { CustomDisableInput } from '../../../utils/text-field-utils/text-field-utils';
import { PhoneFormat } from '../../../utils/text-field-utils/text-field-utils';
import BirthdayPartyBookings from '../birthday-party/birthday-party-bookings.component';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PaymentsIcon from '@mui/icons-material/Payments';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import { styled } from '@mui/material/styles';
import FacilityViewCustomerBirthdayParties from '../facility-view-customer-reservations/facility-view-customer-birthday-parties.component';
import FacilityCustomerGroups from "../facility-customer-groups/facility-customer-groups.component";

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -7,
        top: 0,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const style = {
    position: 'absolute',
    top: '10%',
    left: '1%',
    bottom: '1%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const removeAdditionalEmail = (additionalEmails, additionalEmail) => {
    const existingEmail = additionalEmails.find((email) => email === additionalEmail);
    if (existingEmail) {
        return additionalEmails.filter(email => email !== additionalEmail);
    }

    return [...additionalEmails];
}

const FacilityViewCustomer = ({ facilityCustomerIn, close, refreshCustomerList }) => {

    const [isEditMode, setIsEditMode] = useState(false);
    const { isAdmin, userData } = useContext(UserContext);
    const [isSaving, setIsSaving] = useState(false);
    const [facilityCustomer, setFacilityCustomer] = useState(null);
    const [originalFacilityCustomer, setOriginalFacilityCustomer] = useState(null);
    const [contactInfo, setContactInfo] = useState([]);
    const [upcomingDate, setUpcomingDate] = useState(moment().add(30, 'day').format("YYYY-MM-DD"));
    const [showInvoiceCustomers, setShowInvoiceCustomers] = useState(false);
    const [invoiceTo, setInvoiceTo] = useState(null);
    const { invoiceBillingCustomerName, isWeekly } = { ...invoiceTo };
    const [alertMessage, setAlertMessage] = useState(null);
    const [profileSavedMessage, setProfileSavedMessage] = useState(null);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const [viewBirthdayParties, setViewBirthdayParties] = useState(false);
    const [tabSelected, setTabSelected] = useState('upcoming-reses');

    const navigate = useNavigate();

    useEffect(() => {
        if (facilityCustomerIn) {
            setOriginalFacilityCustomer(Object.assign({}, facilityCustomerIn));
            setFacilityCustomer(facilityCustomerIn);
            setContactInfo(facilityCustomerIn.contactInfo);
            setInvoiceTo(facilityCustomerIn.invoiceTo);

        } else {
            setOriginalFacilityCustomer(null);
            setFacilityCustomer(null);
            setContactInfo(null);
            setInvoiceTo(null)
        }
    }, [facilityCustomerIn]);

    const closeView = () => {
        close();
    }

    const cancelEdit = () => {
        setIsEditMode(false);
        setShowCancelDialog(false);
        setFacilityCustomer(Object.assign({}, originalFacilityCustomer))
        setContactInfo(originalFacilityCustomer.contactInfo);
    }

    const handleSaveDialogClose = () => {
        setShowSaveDialog(false);
    };

    const handleCancelDialogClose = () => {
        setShowCancelDialog(false)
    }

    const handleFacilityUserChange = (event) => {
        const { name, value } = event.target;
        setFacilityCustomer({ ...facilityCustomer, [name]: value });
    }

    const handleContactInfoChange = (event) => {
        const { name, value } = event.target;
        setContactInfo({ ...contactInfo, [name]: value });
    }

    const editAdditionalEmail = (email, index) => {
        additionalEmails[index] = email
        setContactInfo({ ...contactInfo, 'additionalEmails': additionalEmails });
    }

    const addAdditionalEmail = (event) => {

        if (additionalEmails) {
            const emptyEmail = additionalEmails.filter(email => email === " ");
            if (emptyEmail.length <= 0) {
                setContactInfo({ ...contactInfo, 'additionalEmails': [...additionalEmails, " "] });
            }
        } else {
            setContactInfo({ ...contactInfo, 'additionalEmails': [" "] });
        }
    }

    const deleteAdditionalEmail = (email) => {
        setContactInfo({ ...contactInfo, 'additionalEmails': removeAdditionalEmail(additionalEmails, email) });
    }

    const toggleEditMode = () => {

        if (isEditMode) {
            setShowCancelDialog(true);
        } else {
            setIsEditMode(!isEditMode)
        }

    }

    const handleShowSaveDialog = () => {
        setShowSaveDialog(true);
    }

    const saveUserDetails = async () => {

        setShowSaveDialog(false);
        if (contactInfo) {
            if (contactInfo.additionalEmails) {
                for (const additionalEmail of contactInfo.additionalEmails) {
                    if (!validator.isEmail(additionalEmail)) {
                        setAlertMessage(`${additionalEmail} is not a valid email address`);
                        return
                    }
                }
            }
            facilityCustomer.contactInfo = contactInfo;
        }

        if (!validator.isEmail(userEmailAddress)) {
            setAlertMessage(`Invlaid User Email Address`);
            return
        }

        if (mailingZipCode && !validator.isPostalCode(`${mailingZipCode}`, "any")) {
            setAlertMessage(`Invlaid Mailing Zip Code`);
            return
        }

        if (billingZipCode && !validator.isPostalCode(`${billingZipCode}`, "any")) {
            setAlertMessage(`Invlaid Billing Zip Code`);
            return
        }

        setIsSaving(true);

        const response = await saveFacilityCustomer(userData.facilityId, facilityCustomer);
        if (!response.succeeded) {
            setOriginalFacilityCustomer(Object.assign({}, facilityCustomer));
            setAlertMessage(response.message);
        } else {
            if (refreshCustomerList) {
                refreshCustomerList();
            }
            const saveInvoiceResponse = await updateCustomerInvoiceStatus(userData.facilityId, facilityCustomer.userId, allowedToInvoice, invoiceTo && invoiceTo.billingCustomerId ? invoiceTo.billingCustomerId : facilityCustomer.userId, invoiceTo ? invoiceTo.isWeekly : false);
            if (saveInvoiceResponse.status === 200 && saveInvoiceResponse.data && saveInvoiceResponse.data.status === 'success') {
                setIsEditMode(false);

                if (allowedToInvoice) {
                    sendCustomerInvoiceInvoiceEmail(userEmailAddress, userData.facilityId, userFirstName, userLastName);
                }
                setProfileSavedMessage("Saved.  The customer's profile has been updated.");
            } else {
                setAlertMessage('An error occurred.  The customer profile was not saved.');
            }
        }

        setIsSaving(false);
    }

    const showMemberships = () => {

    }

    const showReservations = () => {
        navigate("/facility/customer-view-reservations", { state: { facilityCustomer } });
    }

    const showEvents = () => {

    }
    const selectWeeklyInvoice = () => {
        if (isEditMode) {
            setInvoiceTo({ ...invoiceTo, 'isWeekly': !isWeekly });
        }
    }

    const selectMonthlyInvoice = () => {
        if (isEditMode) {
            setInvoiceTo({ ...invoiceTo, 'isWeekly': false });
        }
    }

    const selectAllowToInvoice = () => {
        if (isEditMode) {
            setFacilityCustomer({ ...facilityCustomer, ["allowedToInvoice"]: !allowedToInvoice });
        }
    }

    const selectBillToCustomer = (billToCustomer) => {
        setInvoiceTo({ ...invoiceTo, 'billingCustomerId': billToCustomer.userId, 'invoiceBillingCustomerName': `${billToCustomer.userFirstName} ${billToCustomer.userLastName}`, 'invoiceBillingEmail': billToCustomer.userEmailAddress, 'invoiceBillingPhone': billToCustomer.userPhoneNumber, 'invoiceBillingStripeKey': billToCustomer.stripeCustomerId });
        closeInvoiceCustomersList();
    }

    const showInvoiceCustomerList = () => {
        if (isEditMode) {
            setShowInvoiceCustomers(true);
        }
    }

    const closeInvoiceCustomersList = () => {
        setShowInvoiceCustomers(false);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const closeProfileSavedAlert = () => {
        setProfileSavedMessage(null);
    }

    const showBirthdayParties = () => {
        setViewBirthdayParties(true);
    }

    const closeBirthdayParitesView = () => {
        setViewBirthdayParties(false);
    }
    const setTabBarSelected = (event, tab) => {
        setTabSelected(tab)
    }

    const refreshCustomers = () => {
        if (refreshCustomerList) {
            refreshCustomerList()
        }
    }

    const currentDate = moment().format("YYYY-MM-DD");
    let emailIndex = -1;

    const { mailingAddress1, mailingAddress2, mailingState, mailingCity, mailingZipCode, billingAddress1, billingAddress2, billingState, billingCity, billingZipCode, additionalEmails = [] } = contactInfo ? contactInfo : {};
    const { userId, userFirstName, userLastName, userPhoneNumber, userEmailAddress, allowedToInvoice } = facilityCustomer ? facilityCustomer : {};

    return (
        <Modal open={facilityCustomerIn ? true : false}>
            <Box sx={{ ...style, width: '98%' }}>
                <Modal sx={{ position: 'absolute', top: '10%', bottom: '-10%', left: '10%', right: '10%' }} open={viewBirthdayParties}>
                    <BirthdayPartyBookings close={closeBirthdayParitesView} customerId={userId} ></BirthdayPartyBookings>
                </Modal>
                <MySportSpaceLoadingView isOpen={isSaving}></MySportSpaceLoadingView>
                <MySportSpaceAlert isOpen={profileSavedMessage ? true : false} message={profileSavedMessage} okButtonText={'OK'} okAction={closeProfileSavedAlert} ></MySportSpaceAlert>
                <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
                {
                    !isSaving && facilityCustomer &&

                    <Stack direction={'row'}>
                        <Box mr={1}>
                            <Stack spacing={3} mr={1}>
                                <Stack mt={1} mb={1} direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <IconButton onClick={closeView}>
                                        <CloseOutlinedIcon />
                                    </IconButton>
                                </Stack>
                                <Avatar sx={{ width: 100, height: 100 }} alt={`${userFirstName} ${userLastName}`} src={userprofileImg} />
                                <FacilityCustomerGroups userId={userId} refreshCustomerList={refreshCustomers}></FacilityCustomerGroups>
                            </Stack>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                            {
                                isAdmin &&
                                <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-end'}>
                                    <Button variant="contained" onClick={toggleEditMode} startIcon={!isEditMode ? <EditOutlinedIcon /> : <EditOffOutlinedIcon />}>{!isEditMode ? `Edit` : 'Cancel'}
                                    </Button>
                                    {
                                        isEditMode &&
                                        <Button variant="contained" onClick={handleShowSaveDialog} startIcon={<SaveOutlinedIcon />}>{`Save`}
                                        </Button>
                                    }
                                </Stack>
                            }
                            <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="firstName" variant="outlined" label="First Name" type="text" name="userFirstName" value={userFirstName} onChange={handleFacilityUserChange} ></CustomDisableInput>
                                <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="lastName" variant="outlined" label="Last Name" type="text" name="userLastName" value={userLastName} onChange={handleFacilityUserChange} ></CustomDisableInput>
                            </Stack>
                            <CustomDisableInput size="small" fullWidth required sx={{ mt: '1.0vh', mb: '1.0vh' }} id="phoneNumber" variant="outlined" label="Phone Number" type="text" name="userPhoneNumber" value={userPhoneNumber} onChange={handleFacilityUserChange}
                                InputProps={{
                                    inputComponent: PhoneFormat,
                                    readOnly: !isEditMode
                                }}></CustomDisableInput>

                            <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="emailAddress" variant="outlined" label="Email Address" type="text" name="userEmailAddress" value={userEmailAddress} onChange={handleFacilityUserChange} ></CustomDisableInput>
                            <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                                <Typography fontWeight={'bold'} textAlign={'left'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.10vw' }}>
                                    Mailing Address
                                </Typography>
                                <Divider></Divider>
                                <Stack direction={'column'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingAddress1" variant="outlined" label="Address 1" type="text" name="mailingAddress1" value={mailingAddress1} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingAddress2" variant="outlined" label="Address 2" type="text" name="mailingAddress2" value={mailingAddress2} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>
                                <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth id="mailingCity" variant="outlined" label="City" type="text" name="mailingCity" value={mailingCity} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingState" variant="outlined" label="State" type="text" name="mailingState" value={mailingState} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="mailingZipCode" variant="outlined" label="Zip Code" type="text" name="mailingZipCode" value={mailingZipCode} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                                <Typography fontWeight={'bold'} mt={2} textAlign={'left'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.10vw' }}>
                                    Billing Address
                                </Typography>
                                <Divider></Divider>
                                <Stack direction={'column'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingAddress1" variant="outlined" label="Address 1" type="text" name="billingAddress1" value={billingAddress1} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingAddress2" variant="outlined" label="Address 2" type="text" name="billingAddress2" value={billingAddress2} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>
                                <Stack direction={'row'} spacing={1} display={'flex'} justifyContent={'flex-start'}>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingCity" variant="outlined" label="City" type="text" name="billingCity" value={billingCity} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingState" variant="outlined" label="State" type="text" name="billingState" value={billingState} onChange={handleContactInfoChange} ></CustomDisableInput>
                                    <CustomDisableInput size="small" disabled={!isEditMode} required fullWidth sx={{ mt: '1.0vh', mb: '1.0vh' }} id="billingZipCode" variant="outlined" label="Zip Code" type="text" name="billingZipCode" value={billingZipCode} onChange={handleContactInfoChange} ></CustomDisableInput>
                                </Stack>
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                                <Typography fontWeight={'bold'} textAlign={'left'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.10vw' }}>
                                    Invoicing
                                </Typography>
                                <Divider></Divider>
                                <Stack spacing={1} ml={1} direction={'row'} display={'flex'} alignItems={'center'} alignContent={'center'}>
                                    <FormControlLabel control={<Checkbox disabled={!isEditMode} checked={allowedToInvoice} onChange={selectAllowToInvoice} />} label="Allow Invoicing" />
                                    {allowedToInvoice &&
                                        <Fragment>
                                            <FormControlLabel control={<Checkbox disabled={!isEditMode} checked={isWeekly} onChange={selectWeeklyInvoice} />} label="Weekly" />
                                            <FormControlLabel control={<Checkbox disabled={!isEditMode} checked={!isWeekly} onChange={selectMonthlyInvoice} />} label="Monthly" />
                                            <Typography id='bill-to-hdr' textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                Bill To:
                                            </Typography>
                                            <Button variant='outlined' disabled={!isEditMode} onClick={showInvoiceCustomerList} >{invoiceBillingCustomerName ? invoiceBillingCustomerName : "This Account"}</Button>
                                            <FacilityInvoiceCustomersList isOpen={showInvoiceCustomers} closeList={closeInvoiceCustomersList} selectCustomer={selectBillToCustomer}></FacilityInvoiceCustomersList>
                                        </Fragment>
                                    }
                                </Stack>
                            </Box>
                            <Stack spacing={1} display={'flex'}>
                                <Stack mb={1} spacing={1} display={'flex'} direction={'row'}>
                                    <Typography ml={1} fontWeight={'bold'} mt={1} textAlign={'left'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.10vw' }}>
                                        Additional Emails
                                    </Typography>
                                    {isEditMode &&
                                        <IconButton size='small' variant="contained" onClick={addAdditionalEmail}>
                                            <AddCircleOutlineOutlinedIcon />
                                        </IconButton>
                                    }
                                </Stack>

                                <Divider></Divider>

                                <Stack spacing={1} display={'flex'}>
                                    {
                                        additionalEmails && additionalEmails.map(email => (
                                            <AdditionalEmailRow key={email} isEditMode={isEditMode} email={email} index={emailIndex += 1} editAdditionalEmail={editAdditionalEmail} deleteAdditionalEmail={deleteAdditionalEmail}></AdditionalEmailRow>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                        </Box>
                        <Divider sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }} orientation="vertical" variant="middle" flexItem></Divider>
                        <Stack mt={1} ml={1} spacing={2} sx={{ height: '90%' }} display={'flex'} justifyContent={'center'} width={'50%'}>
                            <Stack spacing={2} direction={'row'} display={'flex'} justifyContent={'flex-end'}>
                                <Button variant="contained" onClick={showBirthdayParties} startIcon={<FormatListBulletedIcon />}>{`Birthday Parties`}</Button>
                                <Button variant="contained" onClick={showReservations} startIcon={<ManageSearchOutlinedIcon />}>{`Reservations`}</Button>
                            </Stack>
                            <Tabs centered variant="fullWidth" value={tabSelected} onChange={setTabBarSelected} aria-label="home-page-tabs">
                                <Tab sx={{ fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '0.9vw', xl: '0.9vw' } }} value="upcoming-reses" icon={<UpcomingIcon sx={{ fontSize: '1.75vw' }} />} label='Upcoming Reservations' {...a11yProps(0)} />
                                <Tab sx={{ fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '0.9vw', xl: '0.9vw' } }} value="failed-payments" icon={<PaymentsIcon sx={{ fontSize: '1.75vw' }} />} label="Failed Payments" {...a11yProps(1)} />
                                <Tab sx={{ fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '0.9vw', xl: '0.9vw' } }} value="birthday-parties" icon={<CelebrationOutlinedIcon sx={{ fontSize: '1.75vw' }} />} label="Upcoming Birthday Parties" {...a11yProps(2)} />
                            </Tabs>
                            {
                                tabSelected === 'upcoming-reses' &&
                                <FacilityViewCustomerReservations key={'upcoming-reservations'} customerId={userId} startDate={currentDate} endDate={upcomingDate} failedOnly={false}></FacilityViewCustomerReservations>
                            }
                            {
                                tabSelected === 'failed-payments' &&
                                <FacilityViewCustomerReservations key={'failed-payments'} customerId={userId} startDate={null} endDate={null} failedOnly={true}></FacilityViewCustomerReservations>
                            }
                            {
                                tabSelected === 'birthday-parties' &&
                                <FacilityViewCustomerBirthdayParties key={'birthday-parties'} customerId={userId} startDate={currentDate}></FacilityViewCustomerBirthdayParties>
                            }
                        </Stack>
                    </Stack>
                }
                <Dialog
                    open={showSaveDialog}
                    onClose={handleSaveDialogClose}
                >
                    <DialogTitle>Save Customer Profile?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will update a customer's My Sport Space profile.  The customer's email log on will be affected (if changed) and all customer data will be updated in their My Sport Space profile.
                        </DialogContentText>

                        <DialogContentText mt={1}>
                            By updating a customer's My Sport Space profile you are confirming that you have the customer's permission.
                        </DialogContentText>

                        <DialogContentText mt={1}>
                            If you are updating the customer's email address, the customer will receive an email informing them that their profile has changed.
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSaveDialogClose}>Cancel</Button>
                        <Button onClick={saveUserDetails} variant="contained">Save</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showCancelDialog}
                    onClose={handleCancelDialogClose}
                >
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Any unsaved changes will be lost.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDialogClose}>No</Button>
                        <Button onClick={cancelEdit} variant="contained">Yes</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Modal>
    )

}

export default FacilityViewCustomer