
import { FacilityViewResContainer } from './facility-view-reservation.styles';
import { getReservation } from '../../../utils/reservation-api/reservation-service';
import { useEffect, useState, useContext } from 'react';
import MySportSpaceAlert from '../../alert/alert.component';
import ViewReservation from './view-reservation.component';
import ViewBlackout from './view-blackout.component';
import BirthdayPartyViewBooked from '../birthday-party/birthday-party-view-booked.component';
import { GetBookedBirthdayParty } from '../../../utils/birthday-api/birthday-service';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';

const FacilityViewReservation = ({reservationToView, close }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);
    const [reservationDetails, setReservationDetails ] = useState(null);
    const [bookedBirthdayParty, setBookedBirthdayParty] = useState(null);

    const getReservationDetails = async (reservation) => {
        setIsLoading(true);

        if (reservation.reservationType && reservation.reservationType === 'birthdayParty') {
            const response = await GetBookedBirthdayParty(reservation.reservationEventId);
            const { status, data = {} } = response;

            if (status === 200 && data.status === 'success') {
                setBookedBirthdayParty(data.bookedBirthdayParty);
            } else {
                setAlertMessage("Your birthday party details are not available.")
                setBookedBirthdayParty(null);
            }

        }
        else {
            const reservationResponse = await getReservation(reservation.facilityId, reservation.isInternalBooking ? null : reservation.customerId, reservation.dateKey, reservation.reservationId);
            if (reservationResponse && reservationResponse.status === 200 && reservationResponse.data.status === 'success') {
                setReservationDetails(reservationResponse.data.reservation);
            } else {
                setAlertMessage("Your reservation details are not available.")
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (reservationToView) {
            getReservationDetails(reservationToView);
        }

    }, [reservationToView]);

    const closeReservationDetails = () => {
        setReservationDetails(null);
        if (close) {
            close();
        }
    }

    const closeBirthdayParty = () => {
        setBookedBirthdayParty(null);
        setReservationDetails(null);

        if (close) {
            close();
        }
    }

    return (
        <FacilityViewResContainer>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeReservationDetails} ></MySportSpaceAlert>
            {/* <ViewReservation isOpen={!isLoading && reservationDetails && !reservationDetails.isBlackOutPeriod} reservationToView={reservationDetails} close={closeReservationDetails}></ViewReservation> */}
            {
                !isLoading && reservationDetails && !reservationDetails.isBlackOutPeriod &&
                <ViewReservation isOpen={true} reservationToView={reservationDetails} close={closeReservationDetails}></ViewReservation>
            }
            {
                !isLoading && reservationDetails && reservationDetails.isBlackOutPeriod &&
                <ViewBlackout reservation={reservationDetails} close={closeReservationDetails}></ViewBlackout>
            }
            {
                !isLoading && bookedBirthdayParty &&
                <BirthdayPartyViewBooked bookedParty={bookedBirthdayParty} close={closeBirthdayParty} ></BirthdayPartyViewBooked>
            }

        </FacilityViewResContainer >
    )
}

export default FacilityViewReservation