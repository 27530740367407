import { DisplayContext } from "../../../contexts/dispaly-options.context";
import { UserContext } from "../../../contexts/user.context";
import { useContext, useState, useEffect, useCallback } from 'react';
import FacilityCustomerAdd from '../../../components/facility/facility-customer-add/facility-customer-add.component';
import { searchFacilityCustomers, getFacilityCustomers, RemoveCustomerFromUserGroup } from '../../../utils/facility-api/facility-service';
import { Button, TextField, Typography, Chip } from '@mui/material';
import { Box, Stack } from '@mui/material';
import FacilityViewCustomer from '../facility-view-customer/facility-view-customer.component';
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import MySportSpaceAlert from "../../alert/alert.component";

const PAGE_SIZE = 100;

function getRowId(row) {
    return row.userId;
}

let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    let matchWithOne = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (matchWithOne) {
        return matchWithOne[1] + '(' + matchWithOne[2] + ') ' + matchWithOne[3] + '-' + matchWithOne[4]
    };

    return str
};

const FacilityCustomersView = ({ selectRow, selectFacilityCustomer, selectedCustomers, marginTop = '10.0vh' }) => {

    const [customers, setCustomers] = useState([]);
    const [pageIsLoading, setPageIsLoading] = useState(false);
    const [displaySearchResults, setDisplaySearchResults] = useState(false);
    const [searchBtnPressed, setSearchButtonPressed] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [hasMoreCustomers, setHasMoreCustomers] = useState(false);
    const [refreshCustomers, setRefreshCustomers] = useState(false);
    const [customerToView, setCustomerToView] = useState(null);
    const [isSelectingCustomers, setIsSelectingCustomers] = useState(false);
    const [customersSelected, setCustomersSelected] = useState([]);
    const [groupToDelete, setGroupToDelete] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [totalRowCount, setTotalRowCount] = useState(null);
    const [lastKey, setLastKey] = useState(null);
    const [lastLastName, setLastLastName] = useState(null);
    const [firstKey, setFirstKey] = useState(null);
    const [firstLastName, setFirstLastName] = useState(null);
    const [startIndex, setStartIndex] = useState(0);

    const { isViewOpen, addOpenView, removeOpenView } = useContext(DisplayContext);
    const { userData, userIsLoading, hasReservationPrivileges, currentUser } = useContext(UserContext);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: PAGE_SIZE
    });

    const selectCustomer = (customerId) => {
        if (selectRow && selectFacilityCustomer) {
            selectFacilityCustomer(findCustomerById(customerId));
        } else {
            setCustomerToView(findCustomerById(customerId));
        }
    }

    const viewCustomer = (customerId) => {
        setCustomerToView(findCustomerById(customerId));
    }

    const findCustomerById = (customerId) => {

        const foundCustomers = customers.filter((customer) => {
            return customer.userId === customerId;
        })

        if (foundCustomers.length > 0) {
            return foundCustomers[0]
        }

        return null;
    }

    const handlePaginationModelChange = (newPaginationModel) => {
        if (displaySearchResults) {
            if (paginationModel.page < newPaginationModel.page) {
                getCustomers(null, null, null, null, startIndex);
            } else {
                let nextSearchIndex = (startIndex - (PAGE_SIZE * 2));
                if (nextSearchIndex < 0) {
                    nextSearchIndex = 0;
                }
                getCustomers(null, null, null, null, nextSearchIndex);
            }
        } else {
            if (paginationModel.page == 0 || paginationModel.page < newPaginationModel.page) {
                getCustomers(lastKey, lastLastName, null, null);
            } else {
                getCustomers(null, null, firstKey, firstLastName);
            }
        }
        setPaginationModel(newPaginationModel);

    };

    async function getCustomers(startAfterKey, startAfterLastName, startBeforeKey, startBeforeLastName, startSearchIndex) {
        if (!pageIsLoading) {
            setPageIsLoading(true);
        }
        if (currentUser && userData) {
            let allCustomers = [];
            let refreshCustomers = false;
            if (displaySearchResults) {
                refreshCustomers = true;
                const response = await searchFacilityCustomers(userData.facilityId, searchInput.trim(), false);
                if (response && response.data && response.data.customers) {
                    allCustomers = response.data.customers;
                }

                setTotalRowCount(response.data.totalRowCount);
                let remainingCustomers = allCustomers.slice(startSearchIndex);
                if (remainingCustomers.length > PAGE_SIZE) {
                    allCustomers = remainingCustomers.slice(0, PAGE_SIZE);
                    setStartIndex(startSearchIndex + PAGE_SIZE);
                } else {
                    allCustomers = remainingCustomers;
                    setStartIndex(allCustomers.length - 1);
                }
                setSearchButtonPressed(false);
            } else {
                refreshCustomers = true;
                const response = await getFacilityCustomers(userData.facilityId, startAfterKey, startAfterLastName, startBeforeKey, startBeforeLastName, PAGE_SIZE);
                if (response && response.data && response.data.customers) {
                    allCustomers = response.data.customers;

                    if (response && response.data && response.data.hasMoreCustomers) {
                        setHasMoreCustomers(true);
                    } else {
                        setHasMoreCustomers(false);
                    }

                    if (response.data.totalCustomers) {
                        setTotalRowCount(response.data.totalCustomers);
                    } else {
                        setTotalRowCount(null);
                    }

                    if (response.data.lastKey) {
                        setLastKey(response.data.lastKey);
                    } else {
                        setLastKey(null);
                    }

                    if (response.data.lastLastName) {
                        setLastLastName(response.data.lastLastName);
                    } else {
                        setLastLastName(null);
                    }

                    if (response.data.firstKey) {
                        setFirstKey(response.data.firstKey);
                    } else {
                        setFirstKey(null);
                    }

                    if (response.data.firstLastName) {
                        setFirstLastName(response.data.firstLastName);
                    } else {
                        setFirstLastName(null);
                    }

                    if (allCustomers.length <= 0) {
                        refreshCustomers = false;
                    }

                } else {
                    allCustomers = [];
                }

            }
            if (refreshCustomers) {
                setCustomers(allCustomers);
            }
            setPageIsLoading(false);
        }
    }

    useEffect(() => {
        getCustomers(null, null, null, null, 0);
        setStartIndex(0);
        setPaginationModel({
            page: 0,
            pageSize: PAGE_SIZE,
        })
    }, [userData, displaySearchResults, searchBtnPressed, refreshCustomers]);

    const refreshCustomerList = () => {
        setRefreshCustomers(true);
    }

    const closeCustomerAdd = () => {
        removeOpenView('facilityaddcustomer');
    }

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    const searchForCustomer = () => {

        if (searchInput === "") {
            alert("No Search Input Provided");
            return;
        }

        setPageIsLoading(true);
        setDisplaySearchResults(true);
        setPageIsLoading(false);
        setSearchButtonPressed(true);
    }

    const clearCustomerSearch = () => {
        setDisplaySearchResults(false);
        setSearchButtonPressed(false);
        setSearchInput("");
    }

    const addNewCustomer = () => {
        addOpenView('facilityaddcustomer');
    }

    const onKeyUp = (event) => {
        if (event.keyCode === 13) {
            if (searchInput !== "") {
                searchForCustomer();
            } else {
                clearCustomerSearch();
            }
        }
    }

    const handleGroupDelete = (groupToDelete) => () => {
        setGroupToDelete(groupToDelete);
    };

    const removeCustomerFromGroup = async () => {
        setPageIsLoading(true);
        const response = await RemoveCustomerFromUserGroup(groupToDelete.customerId, groupToDelete.id);
        if (response && response.data && response.data.status === 'success') {
            setGroupToDelete(null);
            if (displaySearchResults) {
                getCustomers(null, null, null, null, startIndex);
            } else {
                getCustomers(lastKey, lastLastName, null, null);
            }
        } else {
            setGroupToDelete(null);
            setPageIsLoading(false);
            setAlertMessage("The customer was NOT removed.");
        }
    }

    const closeRemoveAlert = () => {
        setGroupToDelete(null);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const getRowSpacing = useCallback((params) => {
        return {
            top: 1,
            bottom: 1,
        };
    }, []);

    const columns = [
        {
            field: 'userLastName',
            renderHeader: () => (
                <strong>
                    {'Last Name '}
                </strong>
            ),
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }} >{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'userFirstName',
            flex: 0.5,
            minWidth: 25,
            renderHeader: () => (
                <strong>
                    {'First Name '}
                </strong>
            ),
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'userEmailAddress',
            renderHeader: () => (
                <strong>
                    {'Email Address'}
                </strong>
            ),
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? params.value : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'userPhoneNumber',
            renderHeader: () => (
                <strong>
                    {'Phone Number'}
                </strong>
            ),
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '1.0vw', xl: '1.0vw' } }}>{params.value ? formatPhoneNumber(params.value) : ""}</Typography>
                    </Box>
                );
            },
        },
        {
            field: 'userGroups',
            renderHeader: () => (
                <strong>
                    {'Groups'}
                </strong>
            ),
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Box height={'100%'} display={'flex'} alignItems={'center'}>
                        <Stack direction={'row'} overflow={'scroll'} spacing={1}>
                            {
                                params && params.value && params.value.map((group) => {
                                    return <Chip key={group.id} sx={{ fontSize: { xs: '1.5vw', sm: '1.5vw', md: '1.0vw', lg: '0.75vw', xl: '0.75vw' } }} size="small" label={group.name} onDelete={handleGroupDelete(group)} />
                                })
                            }
                        </Stack>
                    </Box>

                );
            },
        },
    ];

    let row = 0;
    return (
        <Box sx={{
            width: '98%',
            marginLeft: '1.0vw',
            marginRight: '1.0vw'
        }}>
            {/* <MySportSpaceLoadingView isOpen={pageIsLoading}></MySportSpaceLoadingView> */}
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage ? alertMessage : ''} okButtonText={"OK"} okAction={closeAlert}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={groupToDelete ? true : false} message={"Are you sure you want to remove this customer from the group?"} okButtonText={"Yes"} cancelButtonText={"No"} okAction={removeCustomerFromGroup} cancelAction={closeRemoveAlert}></MySportSpaceAlert>
            <FacilityViewCustomer facilityCustomerIn={customerToView} close={viewCustomer} refreshCustomerList={refreshCustomerList} ></FacilityViewCustomer>
            <FacilityCustomerAdd isOpen={isViewOpen('facilityaddcustomer') ? true : false} close={closeCustomerAdd} refresh={refreshCustomerList}></FacilityCustomerAdd>
            {
                !userData || !userData.isFacilityUserAccount && !userIsLoading &&
                <div>
                    <span>You are not authorized to view this page.</span>
                </div>
            }
            {
                userData && userData.isFacilityUserAccount &&
                <Box sx={{ height: '80vh', marginTop: marginTop, marginBottom: '10%' }}>
                    <Stack spacing={2} mt={2} mb={2} ml={5} mr={5} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                        <TextField disabled={pageIsLoading}  size='small' fullWidth required id='facility-customer-search-bar' variant="outlined" label="Search by Name, Email or Phone Number" type="text" name="searchInput" value={searchInput} onChange={handleChange} onKeyUp={onKeyUp}></TextField>
                        <Button disabled={pageIsLoading} size='small' onClick={searchForCustomer} variant='contained'>Search</Button>
                        <Button disabled={pageIsLoading}  size='small' onClick={clearCustomerSearch} variant='outlined'>Clear</Button>
                        {
                            hasReservationPrivileges &&
                            <Button disabled={pageIsLoading}  sx={{ width: '15.0vw' }} size='small' onClick={addNewCustomer} variant='outlined'>New Customer</Button>
                        }
                    </Stack>
                    <Box sx={{ width: '100%' }}>
                        <DataGrid sx={{
                            font: 'Helvetica', color: '#14254C',
                            fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                        }} autoHeight getRowId={getRowId} getRowSpacing={getRowSpacing} rowCount={totalRowCount ? totalRowCount : 1} paginationModel={paginationModel} pageSizeOptions={[PAGE_SIZE]} onPaginationModelChange={handlePaginationModelChange} paginationMode="server"
                            rows={customers} getRowHeight={() => 'auto'} loading={pageIsLoading} columns={columns} checkboxSelection={isSelectingCustomers} disableRowSelectionOnClick={false} rowSelectionModel={customersSelected} onRowSelectionModelChange={(ids) => {
                                if (!isSelectingCustomers) {
                                    selectCustomer(ids[0]);
                                } else {
                                    setCustomersSelected(ids)
                                }
                            }}
                            slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
                    </Box>
                </Box>
            }
        </Box>

    )
}

export default FacilityCustomersView