import { v4 as uuid } from 'uuid';

export const base64ToFile = (base64String, fileName) => {
    // Split the Base64 string to remove the metadata (e.g., "data:image/png;base64,")
    const [metadata, base64Data] = base64String.split(',');

    // Decode the Base64 string
    const binaryString = atob(base64Data);

    // Convert the binary string to an array of bytes
    const binaryArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
    }

    // Determine the MIME type from the metadata
    const mimeType = metadata.match(/:(.*?);/)[1];

    // Create a Blob from the binary data
    const file = new File([binaryArray], fileName, { type: mimeType });

    return file;
};

export const base64ToFileWithoutMetadata = (base64String, fileName, mimeType) => {
    // Decode the Base64 string
    const binaryString = atob(base64String);

    // Convert the binary string to an array of bytes
    const binaryArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
    }

    // Create a File from the binary data
    const file = new File([binaryArray], fileName, { type: mimeType });
    file.id = uuid();

    return file;
};

export const extractBetween = (str, start, end) => {
    const regex = new RegExp(`${start}(.*?)${end}`);
    const match = str.match(regex);
    return match ? match[1] : null; // Return the matched string or null if no match is found
}

export const findAllOccurrences = (str, start, end) => {
    const regex = new RegExp(`${start}(.*?)${end}`, 'g');
    let matches = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
        matches.push(match[0]);
    }
    return matches;
}

export const fileToBase64 = async (file) => {
    try {
        const base64String = await readFileAsBase64(file);  // Wait for the file to be converted
        return base64String // Set the base64 string in the state
      } catch (error) {
        console.error("Error converting image to base64:", error);  // Handle any errors
      }
}

export const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onloadend = () => resolve(reader.result);  // Resolve the promise with the base64 string
      reader.onerror = (error) => reject(error);  // Reject the promise if there's an error
  
      reader.readAsDataURL(file);  // Read the file as base64
    });
  };