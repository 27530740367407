import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import { useState } from "react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import AdUnitsOutlinedIcon from '@mui/icons-material/AdUnitsOutlined';
import FacilityEmailBuilderQuil from "./facility-email-builder-quil.component";
import FacilityTextBuilder from "./facility-text-builder.component";

const drawerWidth = 240;

const FacilityNotifications = () => {

    const [ menuToShow, setMenuToShow ] = useState('Send Email');

    const menuItemClicked = (e) => {
        const menuType = e.currentTarget.dataset.id;
        setMenuToShow(menuType)
    }

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'9vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box display={'flex'}
                    component="nav"
                    sx={{ backgroundColor: '#DEDEDE75', width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <List>
                        {['Send Email', 'Send Text'].map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton onClick={menuItemClicked.bind(this)} data-id={text}>
                                    <ListItemIcon>
                                        {index === 0 ? <EmailOutlinedIcon /> : index === 1 ? <TextsmsOutlinedIcon /> : <AdUnitsOutlinedIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
            <Box display={'flex'} width={'100%'} alignItems={'stretch'} justifyContent={'stretch'} overflow={'scroll'}>
                {
                    menuToShow === 'Send Email' &&
                    <FacilityEmailBuilderQuil></FacilityEmailBuilderQuil>
                }
                {
                    menuToShow === "Send Text" &&
                    <FacilityTextBuilder></FacilityTextBuilder>
                }
            </Box>
        </Box> 
    )

}

export default FacilityNotifications;