
import AreaReservationColumn from './area-reservation-column/area-reservation-column.component.jsx'
import { ReservationCalendarContainer, ReservationFlexCalendarContainer } from './reservation-calendar.styles.jsx'
import { Fragment, useContext, useState } from "react";
import { FacilityAreaContext } from '../../../contexts/facility/facility-area-provider.context.jsx';
import { DisplayContext } from '../../../contexts/dispaly-options.context.jsx';
import { FacilityHoursOfOperationContext } from '../../../contexts/facility/facility-hours-of-operation.context.jsx'
import TimesColumn from '../../times-column/times-column.component.jsx'
import LeftSideBar from './left-side-bar/left-side-bar.component.jsx'
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context.jsx';
import LoadingIndicator from '../../loading-indicator/loading-indicator.component.jsx';
import { findHoursOfOperationForDate } from '../../../utils/reservation-utils/reservation-utils.js';
import { UserContext } from '../../../contexts/user.context.jsx';
import ReservationCalendarMenuBar from './reservation-calendar-menu-bar/reservation-calendar-menu-bar.component.jsx';
import Drawer from '@mui/material/Drawer';
import FacilityClickToBook from '../facility-click-to-book/facility-click-to-book.component.jsx';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component.jsx';
import AreasCalendarHeader from './areas-calendar-header/areas-calendar-header.component.jsx';
import { Stack, Box, Fab } from '@mui/material';
import { FacilityOpenBookingTimesContext } from '../../../contexts/facility/facility-open-booking-times.context.jsx';
import dayjs from 'dayjs';
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const momentTz = require('moment-timezone');

const ReservationCalendar = () => {

    const { hoursOfOperation } = useContext(FacilityHoursOfOperationContext);
    const { isLoadingReservations, reservations, reservationsDate } = useContext(FacilityReservationsContext);
    const { openBookingTimesAreActive, openBookingTimes } = useContext(FacilityOpenBookingTimesContext);
    const { calendarDimensions } = useContext(DisplayContext);
    const [showLeftMenu, setShowLeftMenu] = useState(false);
    const { userData } = useContext(UserContext);

    const closeLeftMenu = () => {
        setShowLeftMenu(false);
    }

    const openLeftMenu = () => {
        setShowLeftMenu(true);
    }

    let { facilityAreas } = useContext(FacilityAreaContext);

    if (!hoursOfOperation || isLoadingReservations) {
        return null;
    }

    const facilityTimeZone = userData && userData.facilityAccountInfo ? userData.facilityAccountInfo.timeZone : "America/Chicago";
    let todaysHoursOfOperation = findHoursOfOperationForDate(hoursOfOperation, reservations, facilityTimeZone);

    if (!todaysHoursOfOperation) {
        return null;
    }

    let todaysHoursOfOperationEnd = todaysHoursOfOperation.endDateTime.clone().endOf('hour').add(1, 'minute');
    let todaysHoursOfOperationStart = todaysHoursOfOperation.startDateTime.clone().startOf('hour');

    if (openBookingTimesAreActive) {
        for (const key of Object.keys(openBookingTimes)) {
            const openBookingTime = openBookingTimes[key];

            let openBookingStartDate = todaysHoursOfOperationStart.format("YYYY-MM-DD");
            let openBookingStartTime = todaysHoursOfOperationStart.format("h:mm A");

            let openBookingEndDate = todaysHoursOfOperationEnd.format("YYYY-MM-DD");
            let openBookingEndTime = todaysHoursOfOperationEnd.format("h:mm A");

            if (openBookingTime.startDate) {
                openBookingStartDate = openBookingTime.startDate;
            }
            if (openBookingTime.endDate) {
                openBookingEndDate = openBookingTime.endDate;
            }
            if (openBookingTime.startTime) {
                openBookingStartTime = openBookingTime.startTime;
            }
            if (openBookingTime.endTime) {
                openBookingEndTime = openBookingTime.endTime;
            }

            const openBookingStart = dayjs(`${openBookingStartDate} ${openBookingStartTime}`, "YYYY-MM-DD h:mm A");
            const openBookingEnd = dayjs(`${openBookingEndDate} ${openBookingEndTime}`, "YYYY-MM-DD h:mm A");
            const currentCalendarDate = dayjs.tz(reservationsDate, facilityTimeZone).startOf('day');
            let qualifies = true;

            if (openBookingTime.daysOfWeek) {
                const isoDayOfWeek = currentCalendarDate.day();
                qualifies = openBookingTime.daysOfWeek.includes(isoDayOfWeek);
            }
            if (qualifies) {
               // console.log("RES ", reservationsDate)

                // console.log("CURRENT ", currentCalendarDate.format("YYYY-MM-DD h:mm a"))
                // console.log("START ", openBookingStart.startOf('day').format("YYYY-MM-DD h:mm a"))
                // console.log("END ", openBookingEnd.endOf('day').format("YYYY-MM-DD h:mm a"))
                if (currentCalendarDate.isSameOrAfter(openBookingStart.startOf('day')) && currentCalendarDate.isSameOrBefore(openBookingEnd.endOf('day'))) {
                    const calendarStartDateWithOpenBookingTime = dayjs.tz(dayjs(`${currentCalendarDate.format("YYYY-MM-DD")} ${openBookingStartTime}`, 'YYYY-MM-DD h:mm A'), facilityTimeZone);
                    if (calendarStartDateWithOpenBookingTime.isBefore(todaysHoursOfOperationStart)) {
                        todaysHoursOfOperationStart = calendarStartDateWithOpenBookingTime.clone();
                    }

                    const calendarEndDateWithOpenBookingTime = dayjs.tz(dayjs(`${currentCalendarDate.format("YYYY-MM-DD")} ${openBookingEndTime}`, 'YYYY-MM-DD h:mm A'), facilityTimeZone);
                    if (calendarEndDateWithOpenBookingTime.isAfter(todaysHoursOfOperationEnd)) {
                        todaysHoursOfOperationEnd = calendarEndDateWithOpenBookingTime.clone();
                    }
                }

            //    console.log("O START TIME ", todaysHoursOfOperationStart.format("YYYY-MM-DD h:mm A"))
            //    console.log("O END TIME ", todaysHoursOfOperationEnd.format("YYYY-MM-DD h:mm A"))
            }

        }

        todaysHoursOfOperation = {
            startDateTime: moment(todaysHoursOfOperationStart.format("YYYY-MM-DD h:mm A")),
            endDateTime: moment(todaysHoursOfOperationEnd.format("YYYY-MM-DD h:mm A")),
        }
    }
    const numberOfHours = todaysHoursOfOperation.endDateTime.diff(todaysHoursOfOperation.startDateTime, 'hours') + 1;

    var column = 1;
    for (const fArea of facilityAreas) {
        fArea.column = column;
        column += 1;
    }
    const timeSlots = (numberOfHours * 4);
    let width = 100 * calendarDimensions.zoom_multiplier;
    const preferredWidth = facilityAreas.length * 16;
    if (preferredWidth > (100)) {
        width = preferredWidth;
    }

    const height = 100 * calendarDimensions.zoom_multiplier;
    const reservationStackWidth = width - 4;

    return (
        <Fragment>
            <FacilityClickToBook></FacilityClickToBook>
            <MySportSpaceLoadingView isOpen={isLoadingReservations}></MySportSpaceLoadingView>
            {!isLoadingReservations &&
                <Fragment>

                    <Drawer open={showLeftMenu} onClose={closeLeftMenu} anchor="left">
                        <LeftSideBar closeMenu={closeLeftMenu}></LeftSideBar>
                    </Drawer>
                    <Stack position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
                        <ReservationCalendarMenuBar openMenu={openLeftMenu} />
                        <Stack position={'fixed'} top={'140px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'} overflow={'scroll'}>
                            <Stack mb={2} sx={{ width: `${width}vw`, height: `${height}%`}}>
                                <AreasCalendarHeader></AreasCalendarHeader>
                                <Box sx={{ height: '100%', backgroundColor: 'white', overflow: 'visible' }}>
                                    <Stack sx={{ height: `${height}%` }} display={'flex'} direction={'row'}>
                                        <TimesColumn hoursOfOperation={todaysHoursOfOperation} timeSlots={timeSlots} column={1}></TimesColumn>
                                        <Stack direction={'row'} sx={{ width: `${reservationStackWidth}vw` }}>
                                            {
                                                facilityAreas && facilityAreas.map((facilityArea) => {
                                                    return <AreaReservationColumn key={facilityArea.id} column={facilityArea.column + 1} timeSlots={timeSlots} facilityArea={facilityArea} hoursOfOperation={todaysHoursOfOperation} facilityTimeZone={facilityTimeZone}></AreaReservationColumn>
                                                })
                                            }
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Fragment>
            }
        </Fragment >
    )

}

export default ReservationCalendar
