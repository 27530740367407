import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const momentTz = require('moment-timezone');

const OpenSlot = {
    startDate: null,
    endDate: null,
    facilityArea: null,
    maxEndDate: null
};

export const findAreaForId = (facilityAreas, areaIdToFind) => {

    const existingArea = facilityAreas.find(area => area.id === areaIdToFind);
    if (existingArea) {
        return existingArea;
    }

    for (const fArea of facilityAreas) {
        if (fArea.subAreas) {
            const existingSubKey = Object.keys(fArea.subAreas).find(key => key === areaIdToFind);
            if (existingSubKey) {
                return fArea.subAreas[existingSubKey];
            }
        }
    }

    return null;
}

export const findHoursOfOperationForDate = (hoursOfOperation, reservations, facilityTimeZone) => {

    if (!hoursOfOperation) {
        return null;
    }

    const reservationsDate = reservations.reservationsDate;
    const dayOfWeek = momentTz.tz(reservationsDate, facilityTimeZone).weekday();

    const hoursOfOp = hoursOfOperation[dayOfWeek];
    if (!hoursOfOp) {
        return null;
    }

    const date = momentTz.tz(reservationsDate, facilityTimeZone).format('YYYY-MM-DD');
    let hoursOfOpStart = momentTz.tz(date + ' ' + hoursOfOp.startTime, "YYYY-MM-DD h:mm a", facilityTimeZone);

    hoursOfOp.facilityOpenDateTime = hoursOfOpStart.clone();
    let hoursOfOpEnd = momentTz.tz(date + ' ' + hoursOfOp.endTime, "YYYY-MM-DD h:mm a", facilityTimeZone);
    if (hoursOfOp.endTime.toUpperCase() === "12:00 AM") {
        hoursOfOpEnd = hoursOfOpEnd.add(1, 'days');
    }
    hoursOfOp.facilityCloseDateTime = hoursOfOpEnd.clone();
    // else {
    //     hoursOfOp.facilityCloseDateTime = hoursOfOpEnd.clone();
    //     hoursOfOpEnd = hoursOfOpEnd.startOf('hour');
    // }

    if (reservations.allReservations && reservations.allReservations.length > 0) {

        let earlyResesFound = reservations.allReservations.filter((res) => {
            return res.startDate.isBefore(hoursOfOpStart);
        });

        if (earlyResesFound && earlyResesFound.length > 0) {
            earlyResesFound.sort((res1, res2) => {
                if (res1.startDate.isBefore(res2.startDate)) {
                    return -1;
                }

                if (res1.startDate.isAfter(res2.startDate)) {
                    return 1;
                }

                return 0;
            });
            hoursOfOpStart = earlyResesFound[0].startDate;
        }

        let lateResesFound = reservations.allReservations.filter((res) => {
            return res.endDate.isSameOrAfter(hoursOfOpEnd);
        });

        if (lateResesFound && lateResesFound.length > 0) {
            lateResesFound.sort((res1, res2) => {
                if (res1.endDate.isBefore(res2.endDate)) {
                    return 1;
                }

                if (res1.endDate.isAfter(res2.endDate)) {
                    return -1;
                }

                return 0;
            });
            hoursOfOpEnd = lateResesFound[0].endDate;
        }
    }

    hoursOfOp.endDateTime = hoursOfOpEnd.clone();
    hoursOfOp.startDateTime = hoursOfOpStart.clone();

    return hoursOfOp;
}

export const findOpenTimesForArea = (facilityTimeZone, fArea, reservations, startTime, endTime, column) => {

    const startDateTime = momentTz.tz(startTime, facilityTimeZone);
    const endDateTime = momentTz.tz(endTime, facilityTimeZone);

    let sortedReses = [];
    if (reservations) {
        sortedReses = reservations.sort((res1, res2) => {
            if (res1.startDate < res2.startDate) {
                return -1;
            }
            if (res1.startDate > res2.startDate) {
                return 1;
            }

            if (res1.endDate < res2.endDate) {
                return -1;
            }

            if (res1.endDate > res2.endDate) {
                return 1;
            }
            return 0;
        });
    }

    let openTimes = [];
    let lastEndDate = null;

    const facilityHoursRange = moment.range(startDateTime, endDateTime);
    let resIsWithinHop = false;
    for (const hopResCheck of sortedReses) {

        const reservationRange = moment.range(hopResCheck.startDate, hopResCheck.endDate);
        if (reservationRange.overlaps(facilityHoursRange)) {
            resIsWithinHop = true;
        }
    }

    if (!resIsWithinHop) {
        openTimes = openTimes.concat(createOpenSlots(column, startDateTime.clone(), endDateTime.clone(), fArea));
        return { openTimes: openTimes, lastEndDate: lastEndDate };
    }

    for (let p = 0; p < sortedReses.length; p++) {
        const sortedRes = sortedReses[p];
        //here we are removing the overlapping sub area reservations.  If we are looking for a main area, we just want to
        //to take the "longest" subarea reservation, as if just 1 of the sub areas aren't available, we can't rent a main area.
        for (let index2 = 0; index2 < sortedReses.length; index2++) {
            const resX = sortedReses[index2];
            if (resX.title !== 'Reserved') {
                if (sortedRes.reservationId !== resX.reservationId) {
                    if (dateRangeOverlaps(sortedRes, resX)) {
                        if (resX.startDate < sortedRes.startDate) {
                            sortedRes.startDate = resX.startDate.clone();
                        }

                        if (resX.endDate > sortedRes.endDate) {
                            sortedRes.endDate = resX.endDate.clone();
                        }
                    }
                }
            }
        }

        const sortedResRange = moment.range(sortedRes.startDate, sortedRes.endDate);
        if (sortedResRange.overlaps(facilityHoursRange)) {
            if (p === 0) {
                const milliSecondsFromLastEndDate = sortedRes.startDate.diff(startDateTime);
                if (milliSecondsFromLastEndDate > 0) {
                    // console.log('OPENT SLOT 4 ');
                    openTimes = openTimes.concat(createOpenSlots(column, startDateTime.clone(), sortedRes.startDate.clone(), fArea));
                } else {
                    if (p === sortedReses.length) {
                        const milliSecondsFromLastEndDate = endDateTime - sortedRes.endDate;
                        if (milliSecondsFromLastEndDate > 0) {
                            // console.log('OPENT SLOT 2 ');
                            openTimes = openTimes.concat(createOpenSlots(column, sortedRes.endDate.clone(), endDateTime.clone(), fArea));
                        }
                    }
                }
            } else if (p === sortedReses.length) {
                if (lastEndDate) {
                    const milliSecondsFromLastEndDate = sortedRes.startDate - lastEndDate;
                    if (milliSecondsFromLastEndDate > 0) {
                        const gapMoment = momentTz.tz(lastEndDate.clone(), facilityTimeZone).add((milliSecondsFromLastEndDate / 1000), 'seconds');
                        // console.log('OPENT SLOT 3 ');
                        openTimes = openTimes.concat(createOpenSlots(column, lastEndDate.clone(), gapMoment, fArea));
                    }
                }

                const secondsFromEndHrsOp = endDateTime - sortedRes.endDate;
                if (secondsFromEndHrsOp > 0) {
                    // console.log('OPENT SLOT 4 ');
                    openTimes = openTimes.concat(createOpenSlots(column, sortedRes.endDate.clone(), endDateTime.clone(), fArea));
                }
            } else {
                if (lastEndDate) {
                    // console.log("RES START DATE b4 LAST END DATE " + sortedRes.startDate.format('h:mm a'));
                    const milliSecondsFromLastEndDate = sortedRes.startDate - lastEndDate;
                    // console.log(milliSecondsFromLastEndDate)
                    if (milliSecondsFromLastEndDate > 0) {
                        const gapMoment = momentTz.tz(lastEndDate.clone(), facilityTimeZone).add((milliSecondsFromLastEndDate / 1000), 'seconds');
                        // console.log('OPENT SLOT 5 ');
                        openTimes = openTimes.concat(createOpenSlots(column, lastEndDate.clone(), gapMoment, fArea));
                    }
                }
            }
        }

        lastEndDate = sortedRes.endDate.clone();
    }

    if (lastEndDate && lastEndDate < endDateTime) {
        const milliSecondsFromEndHrsOp = endDateTime - lastEndDate;
        if (milliSecondsFromEndHrsOp > 0) {
            const gapMoment = momentTz.tz(lastEndDate, facilityTimeZone).add((milliSecondsFromEndHrsOp / 1000), 'seconds');
            // console.log('OPENT SLOT 7 ');
            openTimes = openTimes.concat(createOpenSlots(column, lastEndDate.clone(), gapMoment, fArea));

            lastEndDate = endDateTime.clone();
        }
    }


    return { openTimes: openTimes, lastEndDate: lastEndDate };
}

export const createOpenSlots = (column, startDate, endDate, facilityArea) => {
    
    const totalLengthOfSlotinMs = endDate - startDate;
    const totalLengthInSeconds = totalLengthOfSlotinMs / 1000;
    const openSlots = [];

    if (totalLengthInSeconds > 1800) {

        let secondsLeft = totalLengthInSeconds + 0;
        let slotStartDate = moment(startDate).clone();
        while (secondsLeft > 1800) {
            const slotEndDate = slotStartDate.clone().add(1800, 'seconds');
            const maxEndDate = endDate.clone();
            openSlots.push(createOpenSlot(column, slotStartDate, slotEndDate, facilityArea, maxEndDate));
            slotStartDate = slotEndDate.clone();
            secondsLeft = (maxEndDate - slotStartDate) / 1000;
        }
        if (secondsLeft > 0) {
            const slotEnd = slotStartDate.clone().add(secondsLeft, 'seconds');
            const maxEndDate = endDate.clone();
            openSlots.push(createOpenSlot(column, slotStartDate.clone(), slotEnd, facilityArea, maxEndDate));
        }
    } else if (totalLengthOfSlotinMs > 0) {
        openSlots.push(createOpenSlot(column, startDate.clone(), endDate.clone(), facilityArea, endDate.clone()));
    }

    // console.log("OPEN START " + openSlot.startDate.format('h:mm a') + " OPEN END  " + openSlot.endDate.format('h:mm a'));
    return openSlots
}

export const createOpenSlot = (column, startDate, endDate, facilityArea, maxEndDate) => {
    const openSlot = Object.create(OpenSlot);
    openSlot.id = uuid();
    openSlot.column = column;
    openSlot.startDate = startDate.clone();
    openSlot.endDate = endDate.clone();
    openSlot.facilityArea = facilityArea;
    openSlot.maxEndDate = maxEndDate;
    // console.log("OPEN START " + openSlot.startDate.format('h:mm a') + " OPEN END  " + openSlot.endDate.format('h:mm a'));
    return openSlot;

}

export const findOpenTimesForSubArea = (reservations, subAreaArr, timeZone, startDateTime, endDateTime) => {

    const openTimes = [];
    if (subAreaArr && reservations) {
        subAreaArr.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });

        for (const subArea of subAreaArr) {
            const subAreaId = subArea.areaId ? subArea.areaId : subArea.id;
            const reservationsWithoutSubArea = reservations.filter(reservation => !reservation.areaIds.includes(subAreaId));

            for (const subRes of reservationsWithoutSubArea) {
                const startDate = momentTz.tz(subRes.dateKey + ' ' + subRes.resStartTime, "YYYY-MM-DD h:mm A", timeZone);
                const endDate = momentTz.tz(subRes.dateKey + ' ' + subRes.resEndTime, "YYYY-MM-DD h:mm A", timeZone);
                // const openSlots = createOpenSlots(subArea.column, startDateTime ? startDateTime : startDate, endDateTime ? endDateTime : endDate, subArea);
                const openSlots = createOpenSlots(subArea.column, startDate.isSameOrAfter(startDateTime) ? startDate : startDateTime, endDate.isSameOrBefore(endDateTime) ? endDate : endDateTime, subArea);

                for (const openSlot of openSlots) {
                    let foundOverlap = false;
                    const newOpenSlotRange = moment.range(openSlot.startDate, openSlot.endDate);
                    for (let existingOpenSlot of openTimes) {
                        if (existingOpenSlot.facilityArea.id === subAreaId) {
                            const existingOpenSlotRange = moment.range(existingOpenSlot.startDate, existingOpenSlot.endDate)
                            if (newOpenSlotRange.overlaps(existingOpenSlotRange)) {
                                foundOverlap = true;
                            }
                        }
                    }
                    if (!foundOverlap) {
                        openTimes.push(openSlot);
                    }
                }
            }
        }
    }

    return openTimes;
}

export const dateRangeOverlaps = (res1, res2) => {
    if (res1.startDate < res2.startDate && res2.startDate < res1.endDate) return true; // b starts in a
    if (res1.startDate < res2.endDate && res2.endDate < res1.endDate) return true; // b ends in a
    if (res2.startDate < res1.startDate && res1.endDate < res2.endDate) return true; // a in b
    return false;
}
export const findQudifiedcOpenBookingTimeSlots = (openBookingTimes, facilityArea, startDateTime, endDateTime, facilityTimeZone) => {

    const qualifiedOpenBookingTimes = [];
    if (facilityArea.searchStatus === 'inactive') {
        return qualifiedOpenBookingTimes;
    }
    for (const key of Object.keys(openBookingTimes)) {
        const openBookingTime = openBookingTimes[key];

        let qualifies = true;
        if (openBookingTime.areas) {
            qualifies = Object.keys(openBookingTime.areas).includes(facilityArea.id);
        }
        // console.log('area ' + qualifies);
        if (!qualifies) {
            continue;
        }

        let openBookingStartDate = startDateTime.format("YYYY-MM-DD");
        let openBookingStartTime = startDateTime.format("h:mm a");
        // console.log(openBookingStartDate + openBookingStartTime);
        let openBookingEndDate = startDateTime.format("YYYY-MM-DD");
        let openBookingEndTime = endDateTime.format("h:mm a");
        // console.log(openBookingEndDate + openBookingEndTime);
        if (openBookingTime.startDate) {
            openBookingStartDate = openBookingTime.startDate;
        }
        if (openBookingTime.endDate) {
            openBookingEndDate = openBookingTime.endDate;
        }
        if (openBookingTime.startTime) {
            openBookingStartTime = openBookingTime.startTime;
        }
        if (openBookingTime.endTime) {
            openBookingEndTime = openBookingTime.endTime;
        }

        const openBookingStart = facilityTimeZone ? momentTz.tz(`${openBookingStartDate} ${openBookingStartTime}`, "YYYY-MM-DD h:mm A", facilityTimeZone) : moment(`${openBookingStartDate} ${openBookingStartTime}`, "YYYY-MM-DD h:mm a");
        const openBookingEnd = facilityTimeZone ? momentTz.tz(`${openBookingEndDate} ${openBookingEndTime}`, "YYYY-MM-DD h:mm A", facilityTimeZone) : moment(`${openBookingEndDate} ${openBookingEndTime}`, "YYYY-MM-DD h:mm a");
        
        if (openBookingTime.daysOfWeek) {
            const isoDayOfWeek = dayjs(startDateTime.format("YYYY/MM/DD h:mm a"), "YYYY/MM/DD h:mm a").day();
            qualifies = openBookingTime.daysOfWeek.includes(isoDayOfWeek);
        }

        if (!qualifies) {
            continue;
        }

        const currentCalendarDate = momentTz.tz(`${startDateTime.format('YYYY-MM-DD')}`, facilityTimeZone).startOf('day');

        // const compareStartDateTime = facilityTimeZone ? momentTz.tz(`${startDateTime.format('YYYY-MM-DD h:mm A')}`, "YYYY-MM-DD h:mm A", facilityTimeZone) : moment(`${startDateTime.format("YYYY-MM-DD h:mm a")}`, "YYYY-MM-DD h:mm a");
        // const compareEndDateTime = facilityTimeZone ? momentTz.tz(`${endDateTime.format('YYYY-MM-DD h:mm A')}`, "YYYY-MM-DD h:mm A", facilityTimeZone) : moment(`${endDateTime.format("YYYY-MM-DD h:mm a")}`, "YYYY-MM-DD h:mm a");
        qualifies = currentCalendarDate.isSameOrAfter(openBookingStart.startOf('day')) && currentCalendarDate.isSameOrBefore(openBookingEnd.endOf('day'));
        
        if (!qualifies) {
            // console.log("COMP START ", compareStartDateTime.format("YYYY-MM-DD h:mm a"), " COMP END ", compareEndDateTime.format('YYYY-MM-DD h:mm a'));
            // console.log("OPEN START ", openBookingStart.format("YYYY-MM-DD h:mm a"), " OPEN END ", openBookingEnd.format("YYYY-MM-DD h:mm a"))
            // console.log("DOES NOT QUALIFY")
            continue;
        }

        openBookingTime.openBookingStart = facilityTimeZone ? momentTz.tz(`${startDateTime.format('YYYY-MM-DD')} ${openBookingStartTime}`, "YYYY-MM-DD h:mm A", facilityTimeZone) : moment(`${startDateTime.format("YYYY-MM-DD")} ${openBookingStartTime}`, "YYYY-MM-DD h:mm A");
        openBookingTime.openBookingEnd = facilityTimeZone ? momentTz.tz(`${startDateTime.format('YYYY-MM-DD')} ${openBookingEndTime}`, "YYYY-MM-DD h:mm A", facilityTimeZone) : moment(`${startDateTime.format("YYYY-MM-DD")} ${openBookingEndTime}`, "YYYY-MM-DD h:mm A");;

        qualifiedOpenBookingTimes.push(openBookingTime);
    }
    return qualifiedOpenBookingTimes;
}

export const findOpenBookingTimeSlots = (openBookingTimes, facilityArea, startDateTime, endDateTime, facilityTimeZone, calendarDate) => {

    const openBookingTimeSlots = [];
    if (facilityArea.searchStatus === 'inactive') {
        return openBookingTimeSlots;
    }
    for (const key of Object.keys(openBookingTimes)) {
        const openBookingTime = openBookingTimes[key];
        let qualifies = true;
        if (openBookingTime.areas) {
            qualifies = Object.keys(openBookingTime.areas).includes(facilityArea.id);
            if (!qualifies && facilityArea.parentAreaId) {
                qualifies = Object.keys(openBookingTime.areas).includes(facilityArea.parentAreaId);
            }
        }
        // console.log('area ' + qualifies);
        if (!qualifies) {
            continue;
        }

        let openBookingStartDate = startDateTime.format("YYYY-MM-DD");
        let openBookingStartTime = startDateTime.format("h:mm a");
        // console.log(openBookingStartDate + openBookingStartTime);
        let openBookingEndDate = startDateTime.format("YYYY-MM-DD");
        let openBookingEndTime = endDateTime.format("h:mm a");
        // console.log(openBookingEndDate + openBookingEndTime);
        if (openBookingTime.startDate) {
            openBookingStartDate = openBookingTime.startDate;
        }
        if (openBookingTime.endDate) {
            openBookingEndDate = openBookingTime.endDate;
        }
        if (openBookingTime.startTime) {
            openBookingStartTime = openBookingTime.startTime;
        }
        if (openBookingTime.endTime) {
            openBookingEndTime = openBookingTime.endTime;
        }

        const compareStartDateTime = facilityTimeZone ? momentTz.tz(`${startDateTime.format('YYYY-MM-DD h:mm A')}`, "YYYY-MM-DD h:mm A", facilityTimeZone)  : moment(`${startDateTime.format("YYYY-MM-DD h:mm a")}`, "YYYY-MM-DD h:mm a");
       // console.log('COMPARE START ', compareStartDateTime.format("YYYY-MM-DD h:mm A"))
        const compareEndDateTime = facilityTimeZone ? momentTz.tz(`${endDateTime.format('YYYY-MM-DD h:mm A')}`, "YYYY-MM-DD h:mm A", facilityTimeZone)  : moment(`${endDateTime.format("YYYY-MM-DD h:mm a")}`, "YYYY-MM-DD h:mm a");
      //  console.log('COMPARE END ', compareEndDateTime.format("YYYY-MM-DD h:mm A"))
        const compareOpenBookingStartDate = facilityTimeZone ? momentTz.tz(openBookingStartDate, "YYYY-MM-DD", facilityTimeZone).startOf('day')  : moment(openBookingStartDate, "YYYY-MM-DD h:mm a").startOf('day'); 
        const compareOpenBookingEndDate = facilityTimeZone ? momentTz.tz(openBookingEndDate, "YYYY-MM-DD", facilityTimeZone).endOf('day')  : moment(openBookingEndDate, "YYYY-MM-DD h:mm a").endOf('day'); 
        qualifies = compareStartDateTime.isSameOrAfter(compareOpenBookingStartDate) && compareEndDateTime.isSameOrBefore(compareOpenBookingEndDate);

        if (!qualifies) {
            continue;
        }

        if (openBookingTime.daysOfWeek) {
            const isoDayOfWeek = dayjs(startDateTime.format("YYYY/MM/DD h:mm a"), "YYYY/MM/DD h:mm a").day();
            qualifies = openBookingTime.daysOfWeek.includes(isoDayOfWeek);
        }

        if (!qualifies) {
            continue;
        }

        const openBookingStart = facilityTimeZone ? momentTz.tz(`${startDateTime.format('YYYY-MM-DD')} ${openBookingStartTime}`, "YYYY-MM-DD h:mm A", facilityTimeZone)  : moment(`${openBookingStartDate} ${openBookingStartTime}`, "YYYY-MM-DD h:mm a");
        const openBookingEnd = facilityTimeZone ? momentTz.tz(`${startDateTime.format('YYYY-MM-DD')} ${openBookingEndTime}`, "YYYY-MM-DD h:mm A", facilityTimeZone) : moment(`${openBookingEndDate} ${openBookingEndTime}`, "YYYY-MM-DD h:mm a");

        const openSlots = createOpenSlots(facilityArea.column, openBookingStart, openBookingEnd, facilityArea);
        for (const openSlot of openSlots) {
            let foundOverlap = false;
            const newOpenSlotRange = moment.range(openSlot.startDate, openSlot.endDate);
            for (let existingOpenSlot of openBookingTimeSlots) {
                if (existingOpenSlot.facilityArea.id === facilityArea.id) {
                    const existingOpenSlotRange = moment.range(existingOpenSlot.startDate, existingOpenSlot.endDate)
                    if (newOpenSlotRange.overlaps(existingOpenSlotRange)) {
                        foundOverlap = true;
                    }
                }
            }
            if (!foundOverlap) {
                openSlot.isOpenBookingTime = true;
                openBookingTimeSlots.push(openSlot);
            }
        }

    }
    return openBookingTimeSlots;
}

export const formatReservationLengthOrDuration = (length, existingLength, allowQuaterIncrements = true) => {
    if (parseFloat(length) > 24.0) {
        return {
            length: 24.0,
        };
    } else {
        let result = `${length}`.indexOf(".");
        if (result >= 0) {
            let decimalValue = length.substring(result, length.end);
            if (decimalValue.length > 3) {
                return {
                    length: existingLength,
                };;
            }

            if (allowQuaterIncrements && (decimalValue === '.2' || decimalValue === '.7')) {
                let existingResult = `${existingLength}`.indexOf(".");
                let existingDecimalValue = `${existingLength}`.substring(existingResult, existingLength.end);
                if (existingDecimalValue === '.25' || existingDecimalValue === '.75') {
                    return {
                        length: parseInt(length.substring(0, existingResult))
                    };
                } else {
                    return { length: parseFloat(`${length}5`) };
                }

            } else if (allowQuaterIncrements && decimalValue !== '.25' && decimalValue !== '.5' && decimalValue !== '.75') {
                return {
                    length: parseInt(length.substring(0, result)),
                    message: 'Valid values are increments of .25 (ex 1, 1.25, 1.5, 1.75) or use the arrows to increment.'
                }
            } else if (!allowQuaterIncrements && decimalValue !== '.5') {
                return {
                    length: parseInt(length.substring(0, result)),
                    message: 'Valid values are increments of .5 (ex 1, 1.5) or use the arrows to increment.'
                }
            } else {
                return { length: length };
            }
        } else {
            return { length: length };
        }
    }
}

