import FacilityAreaCheckBox from "../facility-area-check-box/facility-area-check-box.component.jsx";
import { useEffect, useState, useContext, Fragment } from "react";
import { findSportsForArea } from "../../../utils/search-utils/search-utils.js";
import { facilityReservationSearch } from "../../../utils/facility-api/facility-service.js";
import MySportSpaceAlert from "../../alert/alert.component.jsx";
import { UserContext } from "../../../contexts/user.context.jsx";
import { useNavigate } from "react-router-dom";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context.jsx";
import dayjs from "dayjs";
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component.jsx";
import { Box, Button, Modal, Stack, Paper, Typography, Popover, Divider, Switch } from "@mui/material";
import FacilityCustomerSearch from "../facility-customer-search/facility-customer-search.component.jsx";
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FacilityReservationNewList from "../facility-reservation-list/facility-reservation-new-list.component.jsx";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component.jsx";
import FormControlLabel from '@mui/material/FormControlLabel';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    disablePortal: true,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            whiteSpace: 'normal'
        },
    },
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll'
};

const FacilityClickToBook = () => {

    const { reservationSearchData, setReservationSearchData, isSearchingForReservations, setIsSearchingForReservations, setSearchResults, setReservationWarnings, reset } = useContext(SearchForReservationContext);
    const { clickToBookOpenSlot, setClickToBookOpenSlot } = useContext(SearchForReservationContext);
    const [sports, setSports] = useState([]);
    const { facilityArea = {} } = clickToBookOpenSlot ? clickToBookOpenSlot : {};
    const [alertMessage, setAlertMessage] = useState('');
    const { userData } = useContext(UserContext);
    const [showBookingDialog, setShowBookingDialog] = useState(false);
    const { startTime } = reservationSearchData;
    const [searchingForCustomer, setSearchingForCustomer] = useState(false);
    const [showBirthdayPackageWarning, setShowBirthdayPackageWarning] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        if (facilityArea.sports) {

            let request = Object.assign({}, reservationSearchData);
            request.facilityArea = facilityArea;
            request.isClickToBook = true;

            request.startTime = clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.startDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour');
            request.searchDate = clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.startDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour');
            request.endTime = clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.endDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour').add(1, 'hour');
            if (clickToBookOpenSlot && clickToBookOpenSlot.maxEndDate) {

                const totalLengthOfSlotinMs = clickToBookOpenSlot.maxEndDate - clickToBookOpenSlot.startDate;
                const totalLengthInSeconds = totalLengthOfSlotinMs / 1000;
                if (totalLengthInSeconds > 1800) {
                    request.endTime = request.startTime.add(1, 'hour');
                }
            }

            if (facilityArea.sports) {
                const areaSports = findSportsForArea(facilityArea);
                request.sport = areaSports.length > 0 ? areaSports[0] : null;
                setSports(areaSports);
            }

            request.numberOfSpaces = 1;

            let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
            if (diffInHours < 0) {
                diffInHours = diffInHours * -1;
            }

            request.reservationLength = diffInHours;

            setReservationSearchData(request);
        } else {
            navigate("/facility/calendar");
        }
    }, [clickToBookOpenSlot]);

    const returnToCalendar = () => {
        reset();
        navigate("/facility/calendar");
    }

    const close = () => {
        setClickToBookOpenSlot(null);
    }

    const searchForReservations = async (event, overrideBirthdayPartPackage) => {
        if (reservationSearchData.endTime.isAfter(reservationSearchData.startTime.clone().endOf('day').add(1, 'minute'))) {

            setAlertMessage("The end time cannot be after midnight.");

            let request = Object.assign({}, reservationSearchData);
            request.endTime = reservationSearchData.startTime.clone().endOf('day').add(1, 'minute');
            let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
            if (diffInHours < 0) {
                diffInHours = diffInHours * -1;
            }

            request.reservationLength = diffInHours;

            setReservationSearchData(request);

            return;
        } else if (reservationSearchData.endTime.isSame(reservationSearchData.startTime)) {
            setAlertMessage("The end time must be greater than start time");
            let request = Object.assign({}, reservationSearchData);
            request.endTime = dayjs(clickToBookOpenSlot.endDate.format("YYYY/MM/DD h:mm a"));

            let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
            if (diffInHours < 0) {
                diffInHours = diffInHours * -1;
            }

            request.reservationLength = diffInHours;

            setReservationSearchData(request);

            return;
        } else if (clickToBookOpenSlot) {

            if (reservationSearchData.endTime.isBefore(reservationSearchData.startTime)) {
                let request = Object.assign({}, reservationSearchData);
                setAlertMessage("The end time cannot be before start time.")

                request.endTime = clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.endDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour').add(1, 'hour');

                let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
                if (diffInHours < 0) {
                    diffInHours = diffInHours * -1;
                }

                request.reservationLength = diffInHours;

                setReservationSearchData(request);
                return;
            } else if (reservationSearchData.endTime.isAfter(clickToBookOpenSlot.maxEndDate)) {
                setAlertMessage("The end time must be within the allowed calendar space.");
                let request = Object.assign({}, reservationSearchData);
                request.endTime = dayjs(clickToBookOpenSlot.maxEndDate.format("YYYY/MM/DD h:mm a"));

                let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
                if (diffInHours < 0) {
                    diffInHours = diffInHours * -1;
                }

                request.reservationLength = diffInHours;
                setReservationSearchData(request);
                return;
            }

            if (!overrideBirthdayPartPackage && clickToBookOpenSlot.facilityArea.sports.includes("Birthday Party Package") && clickToBookOpenSlot.facilityArea.sports.length === 1) {
                setShowBirthdayPackageWarning(true);
                return;
            }
        }

        if (!reservationSearchData.selectedSport || reservationSearchData.selectedSport === '') {
            showValidationMessage("You must select a sport.");
            return;
        }

        if (!reservationSearchData.customer) {
            showValidationMessage('You must select a customer.');
            return;
        }


        setIsSearchingForReservations(true);
        reservationSearchData.startDate = clickToBookOpenSlot.startDate;
        const response = await facilityReservationSearch(reservationSearchData, null, userData.facilityId, userData.internalBookingId, true, null, null, true);
        const { searchResults, errorMessage, reservationWarnings } = response.data;
        if (response.status === 200) {
            if (errorMessage) {
                setSearchResults([]);
                setReservationWarnings([]);
                showValidationMessage(errorMessage);
            } else {
                setSearchResults(searchResults.filter((result) => {
                    return result.reservationId !== "PLACEHOLDER"
                }));
                setReservationWarnings(reservationWarnings);
                setShowBookingDialog(true);
            }
        } else {
            setSearchResults([]);
            setReservationWarnings([]);
        }
        setIsSearchingForReservations(false);
    }

    const setSearchingForReservations = (searching) => {
        setIsSearchingForReservations(searching);
    }
    const setIsSearchingForCustomer = (searching) => {
        setSearchingForCustomer(true);
    }

    const selectFacilityArea = (facilityArea) => {
        let request = Object.assign({}, reservationSearchData)
        request.facilityArea = facilityArea;
        setReservationSearchData(request);
        setSports(findSportsForArea(facilityArea));
    }

    const checkStartTime = () => {
        let request = Object.assign({}, reservationSearchData);
        if (request.startTime.isBefore(clickToBookOpenSlot.startDate)) {
            setAlertMessage("Start Time must within calendar space.");
            request.startTime = clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.startDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour');;
            let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
            if (diffInHours < 0) {
                diffInHours = diffInHours * -1;

                request.reservationLength = diffInHours;
            }
            setReservationSearchData(request);
        }
    }

    const selectStartTime = (time) => {
        let request = Object.assign({}, reservationSearchData);
        request.startTime = time;
        let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
        if (diffInHours < 0) {
            diffInHours = diffInHours * -1;

            request.reservationLength = diffInHours;
        }
        setReservationSearchData(request);
    }

    const selectEndTime = (time) => {

        const endTimeIsMidnight = time.format("h:mm a") === "12:00 am";

        let request = Object.assign({}, reservationSearchData);
        request.endTime = endTimeIsMidnight ? request.startTime.endOf('day').add(1, 'millisecond') : time;
        let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
        if (diffInHours < 0) {
            diffInHours = diffInHours * -1;
        }
        request.reservationLength = diffInHours;

        setReservationSearchData(request);
    }

    const handleCustomerChange = (customerName, customer) => {
        let request = Object.assign({}, reservationSearchData);
        request.customer = customer;
        request.searchCustomerName = customerName;
        setReservationSearchData(request);
    }

    const selectSport = (e) => {

        let request = Object.assign({}, reservationSearchData)
        request.selectedSport = e.target.value;
        request.selectedSubType = null;

        setReservationSearchData(request);
    }

    const showValidationMessage = (message) => {
        setAlertMessage(message);
    }

    const closeAlert = () => {
        setAlertMessage('')
    }

    const closeBookingDialog = () => {
        setShowBookingDialog(false);
    }

    const subAreas = [];
    if (facilityArea && facilityArea.subAreas) {
        const subAreaIds = Object.keys(facilityArea.subAreas)
        for (const id of subAreaIds) {
            const subArea = facilityArea.subAreas[id]
            subAreas.push(subArea);
        }
    }

    const setAsInternalBooking = () => {
        let request = Object.assign({}, reservationSearchData);

        if (reservationSearchData.customer && reservationSearchData.customer.userId === userData.internalBookingId) {
            request.customer = null;
            request.isInternalBooking = false;
            request.searchCustomerName = "";
            setSearchResults([]);
        } else {
            request.customer = {
                userId: userData.internalBookingId,
                userFirstName: "Internal",
                userLastName: "Booking"
            };
            request.searchCustomerName = "Internal Booking";
            request.isInternalBooking = true;
            setSearchResults([]);
        }

        setReservationSearchData(request);
    }

    const overrideBirthdayPartyWarning = () => {
        setShowBirthdayPackageWarning(false);
        searchForReservations(null, true);
    }

    const closeBirhtdayPartyPackageWarning = () => {
        setShowBirthdayPackageWarning(false);
    }

    return (
        <Fragment>
            <MySportSpaceLoadingView isOpen={isSearchingForReservations}></MySportSpaceLoadingView>
            <Modal open={showBookingDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Paper sx={{ overflow: 'scroll' }}>
                    <Box sx={{ ...style, width: '80%' }}>
                        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
                            <Button onClick={closeBookingDialog} startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                        </Box>
                        <Typography fontWeight={'bold'} mt={2} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h5">
                            Search Results
                        </Typography>
                        <FacilityReservationNewList></FacilityReservationNewList>
                    </Box>
                </Paper>
            </Modal>

            <Popover
                id={'customer-click-to-book-popover'}
                open={clickToBookOpenSlot && !isSearchingForReservations && !showBookingDialog ? true : false}
                anchorEl={clickToBookOpenSlot ? clickToBookOpenSlot.anchorEl : null}
                onClose={close}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box mt={1} mb={1} ml={1} mr={1}>
                    <MySportSpaceAlert isOpen={alertMessage !== ''} message={alertMessage} okButtonText={'OK'} okAction={closeAlert}></MySportSpaceAlert>
                    <MySportSpaceAlert isOpen={showBirthdayPackageWarning ? true : false} message={"This area is for Birthday Party Packages only.  Do you want to override this area to book an hourly rate reservation?"} okButtonText={"Yes"} okAction={overrideBirthdayPartyWarning} cancelButtonText={"No"} cancelAction={closeBirhtdayPartyPackageWarning} ></MySportSpaceAlert>
                    <Typography fontWeight={'bold'} mt={2} textAlign={'center'} gutterBottom color={"#14254C"} component="div" variant="h5">
                        Book Reservation
                    </Typography>
                    <Stack spacing={1}>
                        <InputLabel required={true} id="selection-list-label">Space</InputLabel>
                        <Box sx={{ marginBottom: '1.0vh', border: '1px solid #DEDEDE', borderRadius: '5px' }}>
                            <Stack mt={1} mb={1} spacing={1}>
                                <FacilityAreaCheckBox key={`box-id-${facilityArea.id}`} label={facilityArea.name} facilityArea={facilityArea} selectedArea={reservationSearchData.facilityArea} onChange={selectFacilityArea}></FacilityAreaCheckBox>
                                {
                                    subAreas.length > 0 &&
                                    subAreas.map(sArea => (
                                        <FacilityAreaCheckBox key={`box-id-sub-${sArea.id}`} label={sArea.name} facilityArea={sArea} selectedArea={reservationSearchData.facilityArea} onChange={selectFacilityArea}></FacilityAreaCheckBox>
                                    ))
                                }
                            </Stack>
                        </Box>
                        <Stack spacing={1} direction={'row'}>
                            <MaterialUITimePicker headerText="Start Time" views={['hours', 'minutes']} format={'h:mm A'} value={reservationSearchData.startTime} onChange={selectStartTime} onClose={checkStartTime} />
                            <MaterialUITimePicker headerText="End Time" views={['hours', 'minutes']} format={'h:mm A'} value={reservationSearchData.endTime} onChange={selectEndTime} />
                        </Stack>
                        <InputLabel required={true} id="selection-list-label">Sport</InputLabel>
                        <Select
                            labelId={`sports-label`}
                            id={`sports-label-id`}
                            fullWidth
                            value={reservationSearchData.selectedSport ? reservationSearchData.selectedSport : ''}
                            onChange={selectSport}
                            input={<OutlinedInput label={"Sport"} sx={{
                                "& .MuiInputBase-input": {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: 'left',
                                    color: '#14254C'
                                }
                            }} />}
                            MenuProps={MenuProps}
                        >
                            {sports.map((sport) => (
                                <MenuItem key={sport} value={sport}>
                                    <ListItemText primary={sport} />
                                </MenuItem>
                            ))}
                        </Select>
                        <Divider textAlign="left">Select A Customer </Divider>
                        <FacilityCustomerSearch searchCustomerName={reservationSearchData.searchCustomerName} customer={reservationSearchData.customer} handleCustomerChange={handleCustomerChange} setIsSearchingForCustomers={setIsSearchingForCustomer} marginLeft="0" marginRight="0"></FacilityCustomerSearch>
                        <Divider textAlign="left"> Or </Divider>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch name="Is" checked={reservationSearchData.customer && reservationSearchData.customer.userId === userData.internalBookingId} onChange={setAsInternalBooking} />} label="Internal Booking" />
                    </Stack>

                    <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                        <Stack spacing={1} direction={'row'}>
                            <Button id={'book-button'} disabled={!reservationSearchData.customer} variant="contained" onClick={searchForReservations}>Book</Button>
                            <Button variant="outlined" onClick={returnToCalendar}>Close</Button>
                        </Stack>
                    </Box>
                </Box>
            </Popover>
        </Fragment >
    )
}

export default FacilityClickToBook