import { Box, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";



const FacilityNotificationsAudience = ({selectAudience, audience}) => {

    const [ selectedAudience, setSelectedAudience ] = useState('');

    const setupTheAudience = (audience) => {
        setSelectedAudience(audience)
    }
    useEffect(() => {
        setupTheAudience(audience)
    }, [audience])

    const audienceWasSelected = (e) => {
        if (selectAudience) {
            selectAudience(e.target.value);
        }

        setSelectedAudience(e.target.value)
    }

    return (
        <Box>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel required>Select Audience</InputLabel>
                <Select
                    value={selectedAudience}
                    onChange={(e) => audienceWasSelected(e)}
                    label="Select Audience"
                >
                    <MenuItem value="all_customers">All Customers</MenuItem>
                    {/* <MenuItem value="user_input">User Input</MenuItem> */}
                    <MenuItem value="customer_groups">Customer Groups</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export default FacilityNotificationsAudience;