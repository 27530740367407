import { List, Box, Stack, ListItem, CircularProgress, ListItemText, TextField, Button, Typography, Divider } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import { AddCustomerToUserGroup, CreateUserGroup, GetAllFacilityUserGroups } from "../../../utils/facility-api/facility-service";
import { UserContext } from "../../../contexts/user.context";
import MySportSpaceAlert from "../../alert/alert.component";

const FacilityCustomerCreateGroup = ({ close, userId, refresh }) => {

    const [groupName, setGroupName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userGroupsAreLoading, setUserGroupsAreLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [allFacilityUserGroups, setAllFacilityUserGroups] = useState([]);

    const { userData, isAdmin } = useContext(UserContext);

    const getAllFacilityGroups = async (facilityId) => {

        setUserGroupsAreLoading(true);
        const response = await GetAllFacilityUserGroups(facilityId);
        if (response && response.data && response.data.status === 'success') {
            setAllFacilityUserGroups(response.data.userGroups);
        } else {
            setAllFacilityUserGroups([]);
        }
        setUserGroupsAreLoading(false);

    }

    useEffect(() => {
        if (userData) {
            getAllFacilityGroups(userData.facilityId)
        }
    }, [userData]);

    const handleChange = (event) => {
        const { value } = event.target;
        setGroupName(value);
    }

    const closeView = () => {
        if (close) {
            close();
        }
    }

    const createGroupForUser = async () => {

        if (!isAdmin) {
            setAlertMessage("You are not authorized to add customer groups.");
            return;
        }

        setIsLoading(true);

        const response = await CreateUserGroup(userData.facilityId, userId, groupName)
        if (response && response.data && response.data.status === 'success') {
            setSuccessMessage("The group was created and the customer was added to the group.")
        } else {
            setAlertMessage("The group was NOT created");
        }

        setIsLoading(false);

    }

    const closeAlert = () => {
        if (close) {
            close();
        }
    }

    const closeSuccessAlert = () => {

        if (refresh) {
            refresh();
        }

        if (close) {
            close();
        }
    }

    const selectGroup = async (e) => {

        if (!isAdmin) {
            setAlertMessage("You are not authorized to add customers to groups");
            return;
        }

        setIsLoading(true);
        const groupId = e.currentTarget.dataset.id;
        const response = await AddCustomerToUserGroup(userId, groupId);
        if (response && response.data && response.data.status === 'success') {
            setIsLoading(false);
            if (refresh) {
                refresh();
            }
    
            if (close) {
                close();
            }
        } else {
            let message = "The customer was NOT added to the group.";
            if (response && response.data && response.data.message) {
                message = response.data.message;
            }
            setAlertMessage(message);
            setIsLoading(false);
        }
    }

    return (
        <Box margin={'1.0vw'} maxWidth={'25vw'} display={'flex'} flexDirection={'column'} rowGap={1}>
            {
                userGroupsAreLoading &&
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <CircularProgress color="inherit" />
                </Box>
            }
            {!userGroupsAreLoading &&
                <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                    <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
                    <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
                    <MySportSpaceAlert isOpen={successMessage ? true : false} message={successMessage} okButtonText={'OK'} okAction={closeSuccessAlert} ></MySportSpaceAlert>
                    <TextField fullWidth variant='outlined' label={'Group Name'} type="text" value={groupName} onChange={handleChange} ></TextField>
                    <Stack spacing={1} display={'flex'} direction={'row'} justifyContent={'space-between'}>
                        <Button variant="outlined" onClick={closeView}>Cancel</Button>
                        <Button variant="contained" onClick={createGroupForUser}>Create Group</Button>
                    </Stack>
                    {
                        allFacilityUserGroups.length > 0 &&
                        <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                            <Divider textAlign="center">OR</Divider>
                            <Typography fontSize={{xs: '2.25vw', sm: '2.25vw', md: '1.25vw'}}>Select A Group</Typography>
                            <List sx={{ width: '100%' }} component="nav">
                                {
                                    allFacilityUserGroups.map((group) => {
                                        return <ListItem sx={{ width: '100%' }} key={group.id} divider={true} data-id={group.id} onClick={selectGroup.bind(this)}   >
                                            <ListItemText sx={{ fontSize: { xs: '2.0vw', sm: '2.0vw', md: '1.5vw' } }} primary={group.name.toUpperCase()}></ListItemText>
                                        </ListItem>
                                    })
                                }
                            </List>
                        </Box>
                    }
                </Box>
            }
        </Box>
    )

}

export default FacilityCustomerCreateGroup;