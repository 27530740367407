import { UserContext } from "../../../contexts/user.context";
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MySportSpaceAlert from "../../alert/alert.component";
import { GetReservationSettings } from '../../../utils/facility-api/facility-service';
import { Stack, Box, Typography, Button, FormControl, FormControlLabel, Switch, Divider, IconButton } from "@mui/material";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { CustomDisableInput } from "../../../utils/text-field-utils/text-field-utils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AdditionalEmailRow from "../facility-view-customer/additionalEmailRow.componet";
import AdditionalPhoneNumberRow from "../facility-view-customer/additionalPhoneNumberRow.componet";
import dayjs from "dayjs";
import MaterialUIDatePicker from "../../material-ui/date-picker/material-ui-date-picker.component";
import { saveReservationSettings } from "../../../utils/firebase/facility-firebase-utils";
import { getEditLock, removeEditLock } from "../../../utils/firebase/firebase.utils";

const hasWhitespace = (str) => {
    return /\s/.test(str);
}

const defaultFormFields = {
    searchTerm: '',
    mainFacilityColor: "#14254C",
    secondaryFacilityColor: "#8B8681",
    allowWebCalendar: false,
    allowWebCalendarBookings: false
}

const removeNotification = (notifcations, notification) => {
    const existingEmail = notifcations.find((value) => value === notification);
    if (existingEmail) {
        return notifcations.filter(value => value !== notification);
    }

    return [...notifcations];
}

const maxDaysOutHelper = 'Set how manys days from the current date to allow customers to make reservations';
const advanceDaysNoticeHelper = 'The number of days from the start of the current date a customer can make a reservation';
const advanceHoursNoticeHelper = 'The number of hours from the current time a renter can make a reservation.';

const ReservationSettings = () => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const { notifyOnNewReservations = false, allowOnlineReservations = false, notifyOnCancelRes = false, notificationEmails, notificationPhoneNumbers, membershipsOnly = false, daysOutForReservations = 90, advanceDaysNoticeForReservations = 0, advanceHoursNoticeForReservations = 0, maxReservationDate } = formFields;
    const [isEditMode, setIsEditMode] = useState(false);
    const { isAdmin, userData } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [editModeAlert, setEditModeAlert] = useState(null);
    const [originalFormFields, setOriginalFormFields] = useState(null);
    const [leaveMessage, setLeaveMessage] = useState(null);
    const [editLockName] = useState('ReservationSettingsLock');

    const navigate = useNavigate();
    const hiddenInputRef = useRef(null);
    const enterValuesInHiddenInput = () => {
        <hiddenInputRef className="current onclick"></hiddenInputRef>
    };

    const urlChangeHandler = (url, replace = false) => {
        const historyMethod = replace ? "replaceState" : "pushState";
        window.historyMethod = historyMethod;
    };

    useEffect(() => {
        enterValuesInHiddenInput();

        const handlePopState = (event) => {
            event.preventDefault();
            if (isEditMode) {
                setLeaveMessage("Are you sure you want to leave?  Any unsaved information will be lost.");
            } else {
                navigate("/");
            }
        };
        window.addEventListener("popstate", handlePopState);
        window.history.pushState({ modalOpened: false }, "");

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [hiddenInputRef, urlChangeHandler]);

    useEffect(() => {
        const fetchSettings = async (userData) => {
            setIsLoading(true);
            const settingsResponse = await GetReservationSettings(userData.facilityId);
            if (settingsResponse.status === 200) {
                if (settingsResponse.data) {
                    setOriginalFormFields(Object.assign({}, settingsResponse.data));
                    setFormFields(settingsResponse.data);
                }
            }
            setIsLoading(false);
        }

        if (userData) {
            fetchSettings(userData);
        }

        return () => {
            if (userData) {
                removeEditLock(editLockName, userData);
            }
        }

    }, [userData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'advanceHoursNoticeForReservations') {
            setFormFields({ ...formFields, 'advanceDaysNoticeForReservations': 0, [name]: value });
        } else if (name === 'advanceDaysNoticeForReservations') {
            setFormFields({ ...formFields, 'advanceHoursNoticeForReservations': 0, [name]: value });
        } else {
            setFormFields({ ...formFields, [name]: value });
        }
    }

    const setNotifyOnNewReservations = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'notifyOnNewReservations': !notifyOnNewReservations });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setAllowOnlineReservations = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'allowOnlineReservations': !allowOnlineReservations });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setNotifyOnCancels = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'notifyOnCancelRes': !notifyOnCancelRes });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const setAllowMembershipsOnly = () => {
        if (isEditMode) {
            setFormFields({ ...formFields, 'membershipsOnly': !membershipsOnly });
        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const selectMaxReservationDate = (value) => {
        setFormFields({ ...formFields, 'maxReservationDate': value.format("YYYY-MM-DD") });
    }

    const setHasMaxiumumReservationDate = () => {
        if (isEditMode) {
            if (maxReservationDate) {
                setFormFields({ ...formFields, 'maxReservationDate': null });
            } else {
                const dateValue = dayjs().add(120, 'days').format("YYYY-MM-DD");
                setFormFields({ ...formFields, 'maxReservationDate': dateValue });
            }

        } else {
            setAlertMessage("You must be in edit mode to change.")
        }
    }

    const stayInEditMode = () => {
        setEditModeAlert(null);
    }

    const leaveEditMode = () => {
        setFormFields(originalFormFields);
        setIsEditMode(false);
        setEditModeAlert(null);
        removeEditLock(editLockName, userData);
    }

    const cancelPrompt = () => {
        setEditModeAlert("Are you sure you want to cancel editing?  Any unsaved changes will be lost.");
    }

    const edit = async () => {

        if (userData && userData.isFacilityUserAccount && isAdmin) {
            setIsLoading(true);
            const retrievedEditLock = await getEditLock(editLockName, userData);
            setIsLoading(false);
            if (retrievedEditLock) {
                setIsEditMode(true);
            } else {
                setAlertMessage(`Another user is currently editing the reservation settings.`);
            }
        } else {
            setAlertMessage(`You are not authorized to edit this facility area.`);
        }
    }

    const saveSettings = async () => {

        setIsLoading(true);

        const saved = await saveReservationSettings(userData.facilityId, formFields);
        if (saved) {
            setAlertMessage('Your reservation settings have been saved.');
            setIsEditMode(false);
            removeEditLock(editLockName, userData);
        } else {
            setAlertMessage("An error occurred your reservation settings were not saved.");
        }

        setIsLoading(false);

    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const backToSettingsMenu = () => {
        if (isEditMode) {
            setLeaveMessage("Are you sure you want to leave? Any unsaved information will be lost.");
        } else {
            navigate('/facility/settings');
        }
    }

    const editNotificationEmail = (email, index) => {
        notificationEmails[index] = email
        setFormFields({ ...formFields, 'notificationEmails': notificationEmails });
    }

    const addNotificationEmail = (event) => {

        if (notificationEmails) {
            const emptyEmail = notificationEmails.filter(email => email === " ");
            if (emptyEmail.length <= 0) {
                setFormFields({ ...formFields, 'notificationEmails': [...notificationEmails, " "] });
            }
        } else {
            setFormFields({ ...formFields, 'notificationEmails': [" "] });
        }
    }

    const deleteNotificationEmail = (email) => {
        setFormFields({ ...formFields, 'notificationEmails': removeNotification(notificationEmails, email) });
    }

    const editNotificationPhoneNumber = (number, index) => {
        notificationPhoneNumbers[index] = parseInt(number);
        setFormFields({ ...formFields, 'notificationPhoneNumbers': notificationPhoneNumbers });
    }

    const addNotificationPhoneNumber = (event) => {

        if (notificationEmails) {
            const empty = notificationEmails.filter(number => number === 0);
            if (empty.length <= 0) {
                setFormFields({ ...formFields, 'notificationPhoneNumbers': [...notificationPhoneNumbers, 0] });
            }
        } else {
            setFormFields({ ...formFields, 'notificationPhoneNumbers': [0] });
        }
    }

    const deleteNotificationPhoneNumber = (number) => {
        setFormFields({ ...formFields, 'notificationPhoneNumbers': removeNotification(notificationPhoneNumbers, number) });
    }

    const leavePage = () => {
        setLeaveMessage(null);
        navigate('/facility/settings');
    };

    const cancelLeavePage = () => {
        setLeaveMessage(null);
    };

    let emailIndex = -1;
    let phoneNumberIndex = -1

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'75px'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
            <MySportSpaceAlert isOpen={leaveMessage ? true : false} title={'Leave Page?'} message={leaveMessage} okButtonText={'Yes'} okAction={leavePage} cancelButtonText={'No'} cancelAction={cancelLeavePage}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={editModeAlert ? true : false} title={"Cancel Edit?"} message={editModeAlert} okButtonText={'Exit Edit Mode'} okAction={leaveEditMode} cancelButtonText={"Cancel"} cancelAction={stayInEditMode}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <Stack ml={1} mr={1} sx={{ width: '100%' }} display="flex" alignItems={'center'} >
                <button
                    ref={hiddenInputRef}
                    style={{ visibility: "hidden" }}>
                    Test
                </button>
                <Box marginTop={1} sx={{ width: '100%' }} ml={5} display="flex" justifyContent="flex-start" alignItems="flex-start">
                    <Button onClick={backToSettingsMenu} startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Box>
                <Stack sx={{ width: '80%' }} display={'flex'}>
                    <Stack sx={{ width: '100%' }} direction={'row'} mt={2} mb={2}>
                        <Box sx={{ width: '50%' }}>
                            <Typography sx={{ fontSize: '2.95vh' }} fontWeight={'bold'} fontFamily={'Helvetica'} color={"#14254C"} variant="h5">Reservation Settings</Typography>
                        </Box>
                        <Box sx={{ width: '50%' }} display="flex" justifyContent="flex-end" alignItems="center">
                            {isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<SaveOutlinedIcon />} id={'create-button'} variant="contained" type="submit" onClick={saveSettings}>Save</Button>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<CancelOutlinedIcon />} id={'create-button'} variant="contained" onClick={cancelPrompt}>Cancel</Button>
                                </Stack>
                            }
                            {
                                !isEditMode &&
                                <Stack spacing={1} direction={'row'}>
                                    <Button sx={{ fontSize: '1.5vh' }} size="small" startIcon={<EditOutlinedIcon />} id={'create-button'} variant="contained" onClick={edit}>Edit</Button>
                                </Stack>
                            }
                        </Box>
                    </Stack>
                    <form autoComplete="off" onSubmit={saveSettings}>
                        <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }}>
                            <Typography ml={1} sx={{ fontSize: '1.75vh' }} color={"gray"} variant={'subtitle1'}>
                                Customize your reservation controls
                            </Typography>
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="allowOnlineReservations" checked={allowOnlineReservations} onChange={setAllowOnlineReservations} />} label="Allow Online Reservations" />
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="membershipsOnly" checked={membershipsOnly} onChange={setAllowMembershipsOnly} />} label="Membership Only Reservations" />
                            <Stack mt={1} mb={1} spacing={2} direction={'row'} display={'flex'} alignContent={'center'} alignItems={'center'}>
                                <Stack>
                                    <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="maxReservationDate" checked={maxReservationDate ? true : false} onChange={setHasMaxiumumReservationDate} />} label="Maximum Reservation Date" />
                                </Stack>
                                {
                                    maxReservationDate &&
                                    <MaterialUIDatePicker disabled={!isEditMode} labelText="Max Reservation Date" minDate={dayjs().startOf('day')} onChange={selectMaxReservationDate} value={dayjs(maxReservationDate, 'YYYY-MM-DD')}></MaterialUIDatePicker>
                                }
                                <Typography ml={5} sx={{ fontSize: '1.35vh' }} color={"gray"} variant={'subtitle1'}>
                                    The max date a customer can make a reservation, this overrides Maximum Days Out
                                </Typography>

                            </Stack>
                            <Divider></Divider>
                            <Stack mt={2} spacing={2} direction={'row'}>
                                <CustomDisableInput helperText={maxDaysOutHelper} InputProps={{ readOnly: !isEditMode }} disabled={!isEditMode} fullWidth sx={{ color: '#14254C', fontSize: '0.75vw' }} id="daysOutForReservations" variant="outlined" label={`Maximum Days Out - ${dayjs().add(daysOutForReservations, 'days').format("MM/DD/YYYY")}`} type="number" name="daysOutForReservations" value={daysOutForReservations} onChange={handleChange} ></CustomDisableInput>
                                <Divider orientation="vertical" flexItem></Divider>
                                <CustomDisableInput helperText={advanceDaysNoticeHelper} InputProps={{ readOnly: !isEditMode }} disabled={!isEditMode} fullWidth sx={{ color: '#14254C', fontSize: '0.75vw' }} id="advanceDaysNoticeForReservations" variant="outlined" label={`Advance Days Notice`} type="number" name="advanceDaysNoticeForReservations" value={advanceDaysNoticeForReservations} onChange={handleChange} ></CustomDisableInput>
                                <Divider orientation="vertical" flexItem>OR</Divider>
                                <CustomDisableInput helperText={advanceHoursNoticeHelper} InputProps={{ readOnly: !isEditMode }} disabled={!isEditMode} fullWidth sx={{ color: '#14254C', fontSize: '0.75vw' }} id="advanceHoursNoticeForReservations" variant="outlined" label={`Advance Hours Notice`} type="number" name="advanceHoursNoticeForReservations" value={advanceHoursNoticeForReservations} onChange={handleChange} ></CustomDisableInput>
                            </Stack>
                            <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }}></Divider>
                            <Typography sx={{ fontSize: '2.25vh' }} color={"#14254C"} variant={'h6'}>
                                Communications
                            </Typography>
                            <Typography ml={1} sx={{ fontSize: '1.75vh' }} color={"gray"} variant={'subtitle1'}>
                                Customize how you want to be notified on customer reservations
                            </Typography>
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="notifyOnNewReservations" checked={notifyOnNewReservations} onChange={setNotifyOnNewReservations} />} label="Notify On New Reservations" />
                            <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch disabled={!isEditMode} name="notifyOnCancelRes" checked={notifyOnCancelRes} onChange={setNotifyOnCancels} />} label="Notify On Cancelations" />
                            <Stack mt={1} mb={1} spacing={1} display={'flex'}>
                                <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
                                    <Typography sx={{ fontSize: '2.25vh' }} fontFamily={"Helvetica"} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                        Notification emails
                                    </Typography>
                                    {isEditMode &&
                                        <IconButton size='small' variant="contained" onClick={addNotificationEmail}>
                                            <AddCircleOutlineOutlinedIcon />
                                        </IconButton>
                                    }
                                </Box>
                                <Typography ml={1} sx={{ fontSize: '1.75vh' }} color={"gray"} variant={'subtitle1'}>
                                    Emails will be sent to these email addresses
                                </Typography>
                                <Stack mb={1} spacing={1} display={'flex'}>
                                    {
                                        notificationEmails && notificationEmails.map(email => (
                                            <AdditionalEmailRow key={email} isEditMode={isEditMode} email={email} index={emailIndex += 1} editAdditionalEmail={editNotificationEmail} deleteAdditionalEmail={deleteNotificationEmail}></AdditionalEmailRow>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                            <Stack mt={1} mb={1} spacing={1} display={'flex'}>
                                <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
                                    <Typography sx={{ fontSize: '2.25vh' }} fontFamily={"Helvetica"} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                        Notification Phone Numbers
                                    </Typography>
                                    {isEditMode &&
                                        <IconButton size='small' variant="contained" onClick={addNotificationPhoneNumber}>
                                            <AddCircleOutlineOutlinedIcon />
                                        </IconButton>
                                    }
                                </Box>
                                <Typography ml={1} sx={{ fontSize: '1.75vh' }} color={"gray"} variant={'subtitle1'}>
                                    Texts will be sent to these phone numbers
                                </Typography>
                                <Stack mb={1} spacing={1} display={'flex'}>
                                    {
                                        notificationPhoneNumbers && notificationPhoneNumbers.map(number => (
                                            <AdditionalPhoneNumberRow key={number} isEditMode={isEditMode} phoneNumber={number} index={phoneNumberIndex += 1} editAdditionalPhoneNumber={editNotificationPhoneNumber} deleteAdditionalPhoneNumber={deleteNotificationPhoneNumber}></AdditionalPhoneNumberRow>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                            <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }}></Divider>
                        </FormControl >
                    </form>
                </Stack>
            </Stack >
        </Box >





    )
}

export default ReservationSettings;