import React, { useState, useContext } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Stack,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import FacilityNotificationsAudience from './facility-notifications-audience.component';
import { SendFacilityCustomersText } from '../../../utils/facility-api/facility-service';
import MySportSpaceAlert from '../../alert/alert.component';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { UserContext } from '../../../contexts/user.context';
import FacilityCustomerSelectGroup from '../facility-customer-groups/facility-customer-select-group.component';

const FacilityTextBuilder = () => {

    const { userData } = useContext(UserContext);
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [selectedAudience, setSelectedAudience] = useState(null);
    const [phoneNumbers, setPhoneNumbers] = useState("");
    const [ customerGroups, setCustomerGroups ] = useState([]);
    const maxCharacters = 130;

    const handlePhoneNumberChange = (event) => {

        const value = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
        const formattedNumbers = value
            .match(/.{1,10}/g) // Split the string into chunks of 10 digits
            ?.join("\n") || ""; // Join them with new lines if there's a match
        setPhoneNumbers(formattedNumbers);

    };

    const handlePhoneKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent default behavior of Enter
            const value = event.target.value.trim();

            if (value.split("\n").slice(-1)[0].length < 10) {
                alert("Enter a complete 10-digit number before adding a new line.");
                return;
            }

            setPhoneNumbers((prev) => prev + "\n");
        }
    };


    const handleSendText = async () => {
        if (!selectedAudience) {
            setAlertMessage('You must select an Audience');
            return;
        }
        if (!message && !image) {
            setAlertMessage('You must provide an image or message text');
            return;
        }

        if (selectedAudience === 'user_input' && (!phoneNumbers || phoneNumbers.length <= 0)) {
            setAlertMessage('You must provide at least 1 email address');
            return;
        }

        if (selectedAudience === 'customer_groups' && (!customerGroups || customerGroups.length <= 0)) {
            setAlertMessage('You must provide at least 1 customer group');
            return;
        }

        setLoading(true);
        setAlertMessage('');

        let numbers = [];
        if (phoneNumbers.length > 0) {
            numbers = phoneNumbers
                .split("\n")
                .map((num) => num.trim())
                .filter((num) => num);
        }

        const response = await SendFacilityCustomersText(userData.facilityId, selectedAudience, message, image, numbers, customerGroups);
        console.log(response)
        if (response && response.data && response.data.status === 'success') {
            let message = "The text has been sent.";
            if (response.data.message) {
                message = response.data.message;
            }
            setAlertMessage(message);
        } else {
            setAlertMessage("Something went wrong and your text was not sent");
        }

        setLoading(false);
    };

    const resetForm = () => {
        setCustomerGroups([]);
        setAlertMessage(null);
        setSelectedAudience(null);
        setMessage('');
        setImage(null);
        setPhoneNumbers('');
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const handleMessageChange = (e) => {
        const inputMessage = e.target.value;
        if (inputMessage.length <= maxCharacters) {
            setMessage(inputMessage);
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setImage(reader.result); // Convert to Base64
            reader.readAsDataURL(file);
        }
    };

    const selectTheAudience = (audience) => {
        setPhoneNumbers('');
        setCustomerGroups([]);
        setSelectedAudience(audience);
    }

    const customerGroupsWereSelected = (groups) => {
        setCustomerGroups(groups)
    }

    return (
        <Box
            sx={{
                minWidth: '50vw',
                maxWidth: '50vw',
                margin: '2rem auto',
                padding: '1rem',
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: 'background.paper',
                overflow: 'scroll'
            }}
        >
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={!isLoading && alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography fontWeight={'bold'} fontSize={'1.5vw'} gutterBottom>Create and Send Text</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendText}
                    startIcon={<SendIcon />}
                    disabled={isLoading || (!message && !image)}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Send'}
                </Button>
            </Stack>
            <FacilityNotificationsAudience selectAudience={selectTheAudience} />
            {
                selectedAudience === 'user_input' &&
                <Stack>
                    <Typography variant="h6" gutterBottom>
                        {`Enter Phone Numbers (One per Line Format with Area Code )`}
                    </Typography>
                    <TextField
                        label="Phone Numbers"
                        multiline
                        rows={6}
                        value={phoneNumbers}
                        onChange={handlePhoneNumberChange}
                        onKeyDown={handlePhoneKeyPress}
                        placeholder="Enter each phone number on a new line"
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: 2 }}
                    />
                </Stack>
            }
            {
                selectedAudience === 'customer_groups' &&
                <FacilityCustomerSelectGroup selected={customerGroups} selectGroups={customerGroupsWereSelected}></FacilityCustomerSelectGroup>
            }
            <TextField
                label="Message"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                value={message}
                onChange={handleMessageChange}
                placeholder="Type your message here..."
                required
            />

            <Typography
                variant="body2"
                color={message.length === maxCharacters ? 'error' : 'textSecondary'}
                align="right"
                sx={{ mt: -1 }}
            >
                {message.length}/{maxCharacters} characters
            </Typography>

            <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems="center" mt={2} mb={2} rowGap={1}>
                {image && (
                    <img
                        src={image}
                        alt="Preview"
                        style={{
                            maxWidth: '25vw',
                            maxHeight: '25vh',
                            borderRadius: '4px',
                            objectFit: 'cover',
                        }}
                    />
                )}
                <Button

                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                >
                    Upload Image
                    <input
                        type="file"
                        accept="image/png, image/jpeg"
                        hidden
                        onChange={handleImageUpload}
                    />
                </Button>
                <Typography color={"darkgray"} fontSize={{ xs: '1.25', sm: '1.25', md: '1.0vw', lg: '0.75vw', xl: '0.75vw' }}>There is a 4MB limit</Typography>
            </Box>
        </Box>
    );
};

export default FacilityTextBuilder;
