import { Typography, Box, Stack, Divider, IconButton, Popover, CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import FacilityCustomerCreateGroup from "./facility-customer-create-group.component";
import { GetCustomersGroups, RemoveCustomerFromUserGroup } from "../../../utils/facility-api/facility-service";
import { UserContext } from "../../../contexts/user.context";
import MySportSpaceAlert from "../../alert/alert.component";

const FacilityCustomerGroups = ({ userId, refreshCustomerList }) => {

    const [customerGroups, setCustomerGroups] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userData, isAdmin } = useContext(UserContext);
    const [ removeMessage, setRemoveMessage ] = useState(null);
    const [ removeFromThisGroup, setRemoveFromThisGroup ] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getCustomersUserGroups = async (customerId, facilityId) => {
        if (!isLoading) {
            setIsLoading(true);
        }
        
        const response = await GetCustomersGroups(facilityId, customerId);
        if (response && response.data && response.data.status === 'success') {
            setCustomerGroups(response.data.userGroups);
        } else {
            setCustomerGroups([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (userData) {
            getCustomersUserGroups(userId, userData.facilityId);
        }

    }, [userId, userData]);

    const groupSelected = (e) => {

        if (!isAdmin) {
            setAlertMessage("You are not authorized to remove customers from groups");
            return;
        }

        const groupId = e.currentTarget.dataset.id;
        setRemoveFromThisGroup(groupId);
        setRemoveMessage('Are you sure you want to remove this customer from the group?')
    }

    const removeCustomerFromGroup = async () => {
        setRemoveMessage(null);
        setIsLoading(true);
        const response = await RemoveCustomerFromUserGroup(userId, removeFromThisGroup);
        if (response && response.data && response.data.status === 'success') {
            setCustomerGroups([]);
            setRemoveFromThisGroup(null);
            getCustomersUserGroups(userId, userData.facilityId);
            setIsLoading(false);
        } else {
            setAlertMessage("The customer was NOT removed.")
        }
    }

    const closeRemoveAlert = () => {
        setRemoveFromThisGroup(null);
        setRemoveMessage(null);
    }

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const refreshGroups = () => {
        getCustomersUserGroups(userId, userData.facilityId);

        if (refreshCustomerList) {
            refreshCustomerList();
        }
    }

    const open = Boolean(anchorEl);
    return (
        <Box width={'15vw'} display={'flex'} flexDirection={'column'} rowGap={1}>
            {
                isLoading &&
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <CircularProgress color="inherit" />
                </Box>
            }
            {!isLoading &&
                <Box>
                    <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
                    <MySportSpaceAlert isOpen={removeMessage ? true : false} message={removeMessage} okButtonText={'Remove'} okAction={removeCustomerFromGroup} cancelButtonText={"Do Not Remove"} cancelAction={closeRemoveAlert}  ></MySportSpaceAlert>
                    <Stack direction={'row'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography width={'100%'} fontWeight={'bold'} fontSize={{ xs: '2.25vw', sm: '2.25vw', md: '1.10vw' }}>Groups</Typography>
                        <Stack direction={'row'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                            <IconButton onClick={handleClick}>
                                <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <Popover
                                id={'add-group-popover'}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <FacilityCustomerCreateGroup close={handleClose} userId={userId} refresh={refreshGroups} />
                            </Popover>
                        </Stack>
                    </Stack>
                    <Divider></Divider>
                    {
                        <List sx={{ width: '100%' }} component="nav">
                            {
                                customerGroups.map((group) => {
                                    return <ListItem secondaryAction={
                                        <IconButton onClick={groupSelected.bind(this)} aria-label="remove" data-id={group.id} >
                                            <RemoveCircleOutlineOutlinedIcon />
                                        </IconButton>
                                    } sx={{ width: '100%' }} key={group.id} divider={true}  >
                                        <ListItemText sx={{ fontSize: { xs: '2.0vw', sm: '2.0vw', md: '1.5vw' } }} primary={group.name.toUpperCase()}></ListItemText>
                                    </ListItem>
                                })
                            }
                        </List>
                    }
                </Box>
            }

        </Box>
    )

}

export default FacilityCustomerGroups;