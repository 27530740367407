import { AreaReservationColumnContainer } from './area-reservation-column.styles.jsx'
import CalendarReservation from '../caldendar-reservation/calendar-reservation.component.jsx'
import OpenTimeCalendarRow from '../open-time-calendar-row/open-time-calendar-row.component.jsx'
import CalendarOpenReservation from '../calendar-open-reservation/calendar-open-reservation.component.jsx';
import { useContext } from "react";
import { findOpenTimesForArea, findOpenTimesForSubArea, findOpenBookingTimeSlots } from "../../../../utils/reservation-utils/reservation-utils.js";
import { FacilityOpenBookingTimesContext } from '../../../../contexts/facility/facility-open-booking-times.context.jsx';
import { useTextWidth } from '@tag0/use-text-width';
import { FacilityReservationsContext } from '../../../../contexts/facility/facility-reservations.context.jsx';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const AreaReservationColumn = ({ timeSlots, facilityArea, column, hoursOfOperation, facilityTimeZone }) => {

    const { reservations, reservationsDate } = useContext(FacilityReservationsContext);
    const { openBookingTimesAreActive, openBookingTimes } = useContext(FacilityOpenBookingTimesContext);

    let facilityAreaSize = 1;
    if (facilityArea && facilityArea.subAreas) {
        facilityAreaSize = Object.keys(facilityArea.subAreas).length;
    }
    var rows = [], i = 0;
    while (++i <= timeSlots) rows.push(i);

    const { allReservations } = reservations;
 
    const reservationsForArea = allReservations && allReservations.filter((res) => {
        return res.mainAreaReservedType !== 'sub' && res.areaIds && res.areaIds.includes(facilityArea.id);
    });

    const reservationsForSubAreas = allReservations && allReservations.filter((res) => {
        return res.mainAreaReservedType === 'sub' && res.areaIds && res.areaIds.includes(facilityArea.id);
    });

    const blackoutsForArea = reservationsForArea && reservationsForArea.filter((res) => {
        return res.isBlackOutPeriod;
    });
    const blackoutsForSubAreas = reservationsForSubAreas && reservationsForSubAreas.filter((res) => {
        return res.isBlackOutPeriod;
    });

    const nonBlackoutsForArea = reservationsForArea && reservationsForArea.filter((res) => {
        return !res.isBlackOutPeriod;
    });
    const nonBlackoutsForSubAreas = reservationsForSubAreas && reservationsForSubAreas.filter((res) => {
        return !res.isBlackOutPeriod;
    });

    let endDateTime = hoursOfOperation.endDateTime.clone();
    let startDateTime = hoursOfOperation.startDateTime.clone();

    const openCalendarSlots = findOpenTimesForArea(facilityTimeZone, facilityArea, reservationsForArea, startDateTime, endDateTime.endOf('hour').add(1, 'minute'));

    let allSubAreaOpenSlots = [];
    let openBookingTimeSlots = [];

    let nameForWidth = facilityArea.name;
    const numberOfColumns = facilityArea.subAreaList ? facilityArea.subAreaList.length : 1;
    if (facilityArea.subAreas && Object.keys(facilityArea.subAreas).length > 0) {
        let columnCount = 1;
        const subAreaArr = [];
        let longestSubAreaName = "";
        for (const subAreaId of Object.keys(facilityArea.subAreas)) {
            const subArea = facilityArea.subAreas[subAreaId];
            if (subArea.name.length > longestSubAreaName.length) {
                longestSubAreaName = subArea.name;
            }
            subArea.id = subAreaId;
            subArea.column = columnCount;
            subAreaArr.push(subArea);

            if (openBookingTimesAreActive) {
                const subOpenBookingSlots = findOpenBookingTimeSlots(openBookingTimes, subArea, startDateTime, endDateTime, facilityTimeZone, reservationsDate);
                openBookingTimeSlots = openBookingTimeSlots.concat(subOpenBookingSlots);
            }

            columnCount += 1;
        }
        if (longestSubAreaName.length > nameForWidth.length) {
            nameForWidth = longestSubAreaName;
        }
        allSubAreaOpenSlots = findOpenTimesForSubArea(reservationsForSubAreas, subAreaArr, facilityTimeZone, startDateTime, endDateTime);
    } else {
        if (openBookingTimesAreActive) {
            openBookingTimeSlots = findOpenBookingTimeSlots(openBookingTimes, facilityArea, startDateTime, endDateTime, facilityTimeZone);
        }
    }

    let columnWidth = useTextWidth({ text: nameForWidth, font: '15px Times' });
    if (columnWidth < 100.0) {
        columnWidth = 100.0;
    }

    return (
        <Grid sx={{ width: '100%', height: '100%' }} container columns={facilityAreaSize}>
            <AreaReservationColumnContainer $numberofcolumns={facilityAreaSize} $numberofrows={timeSlots} >
                {
                    rows.map((row) => {
                        return <OpenTimeCalendarRow key={`${row}-{reservation.reservationId}`} rowNumber={row} totalColumns={facilityAreaSize} borderColorIn={openBookingTimesAreActive ? 'white' : '#DEDEDE'} backgroundColor={openBookingTimesAreActive ? 'rgba(0, 0, 0, 0.15)' : 'white'}></OpenTimeCalendarRow>
                    })
                }
                {openBookingTimeSlots.length > 0 &&
                    openBookingTimeSlots.map((openslot) => {
                        return <CalendarOpenReservation facilityArea={facilityArea} key={`calendar-sub-open-slot-${openslot.id}`} openSlot={openslot} bgColor={'RGBA(72, 113, 247, 0.25)'} column={'1'} hoursOfOperation={hoursOfOperation}> </CalendarOpenReservation>
                    })
                }
                {
                    openCalendarSlots && openCalendarSlots.openTimes.map((openslot) => {
                        return <CalendarOpenReservation facilityArea={facilityArea} key={`calendar-open-slot-${openslot.id}`} openSlot={openslot} column={'1'} hoursOfOperation={hoursOfOperation}> </CalendarOpenReservation>
                    })
                }
                {
                    allSubAreaOpenSlots && allSubAreaOpenSlots.length > 0 && allSubAreaOpenSlots.map((openslot) => {
                        return <CalendarOpenReservation facilityArea={facilityArea} key={`calendar-sub-open-slot-${openslot.id}`} openSlot={openslot} column={'1'} hoursOfOperation={hoursOfOperation}> </CalendarOpenReservation>
                    })
                }
                {
                    blackoutsForArea && blackoutsForArea.map((reservation) => {
                        return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                    })
                }
                {
                    blackoutsForSubAreas && blackoutsForSubAreas.map((reservation) => {
                        return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                    })
                }
                {
                    nonBlackoutsForSubAreas && nonBlackoutsForSubAreas.map((reservation) => {
                        return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                    })
                }
                {
                    nonBlackoutsForArea && nonBlackoutsForArea.map((reservation) => {
                        return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                    })
                }
            </AreaReservationColumnContainer>
        </Grid>
    )
}

export default AreaReservationColumn