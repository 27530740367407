import { GlobalStyles } from "@mui/system";

function QuillStyles() {
  return (
    <GlobalStyles
      styles={{
        ".ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before": {
          content: "attr(data-value) !important",
        },
      }}
    />
  );
}

export default QuillStyles;