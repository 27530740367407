import { Box, FormControl, MenuItem, Select, InputLabel, CircularProgress, OutlinedInput, Chip } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { GetAllFacilityUserGroups } from "../../../utils/facility-api/facility-service";
import { UserContext } from "../../../contexts/user.context";
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(id, selectedGroups, theme) {
    const foundGroups = selectedGroups.filter(g => g.id === id);
    return {
        fontWeight: foundGroups.length > 0
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}


const FacilityCustomerSelectGroup = ({ selectGroups, selected = [] }) => {
    const [selectedGroups, setSelectedGroups] = useState(selected);
    const [allFacilityUserGroups, setAllFacilityUserGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { userData } = useContext(UserContext);
    const theme = useTheme();

    const getAllFacilityGroups = async (facilityId) => {

        setIsLoading(true);
        const response = await GetAllFacilityUserGroups(facilityId);
        if (response && response.data && response.data.status === 'success') {
            setAllFacilityUserGroups(response.data.userGroups);
        } else {
            setAllFacilityUserGroups([]);
        }
        setIsLoading(false);

    }

    useEffect(() => {
        if (userData) {
            getAllFacilityGroups(userData.facilityId)
        }
    }, [userData]);

    const groupWasSelected = (e) => {
        const groupsSelected = e.target.value;
        setSelectedGroups(groupsSelected);

        if (selectGroups) {
            selectGroups(groupsSelected);
        }
    }

    return (
        <Box>
            {
                isLoading &&
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <CircularProgress color="inherit" />
                </Box>
            }
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel required>Select Group</InputLabel>
                <Select
                    value={selectedGroups}
                    multiple
                    onChange={(e) => groupWasSelected(e)}
                    input={<OutlinedInput id="select-multiple-chip" label="Select Groups" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((group) => (
                                <Chip key={group.id} label={group.name} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {
                        allFacilityUserGroups.map((group) => {
                            return <MenuItem key={group.id} value={group} style={getStyles(group.id, selectedGroups, theme)}
                            >{group.name} </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    )
}

export default FacilityCustomerSelectGroup;