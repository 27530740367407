
import { UserContext } from '../../contexts/user.context';
import { useNavigate } from 'react-router-dom'
import { FacilityReservationsContext } from '../../contexts/facility/facility-reservations.context';
import { signOutUser } from '../../utils/firebase/firebase.utils';
import logo from '../../assets/logo-01.png'
import { Fragment, useContext } from "react";
import { useState } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AppBar from '@mui/material/AppBar';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Popover from '@mui/material/Popover';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import MySportSpaceAlert from '../alert/alert.component';
import { SearchForReservationContext } from '../../contexts/search-for-reservation/search-for-reservation.context';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';


const NavigationFacility = () => {

    const { currentUser, userData, userIsLoading, isAdmin, hasReservationPrivileges, setCurrentUser } = useContext(UserContext);
    const { reset } = useContext(SearchForReservationContext)
    const { clearReservationData } = useContext(FacilityReservationsContext);
    const [expandBirthdayParties, setExpandBirthdayParties] = useState(false);
    const [expandManageBirthdayParties, setExpandManagedBirthdayParties] = useState(false);
    const [expandAreas, setExpandAreas] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);

    const navigate = useNavigate();

    const signOut = async () => {
        await signOutUser();
        setCurrentUser(null, null);
        clearReservationData();
        reset();
        navigate("/");
    }

    const signIn = async () => {
        navigate("/auth");
    }

    const goToFacilityCalendar = () => {
        navigate('/facility/calendar');
        setAnchorElNav(null);
    }

    const goToCustomers = () => {
        navigate('/facility/customers');
        setAnchorElNav(null);
    }

    const goToFacilitySettings = () => {
        navigate('/facility/settings');
        setAnchorElNav(null);
    }

    const goToNotificationSettings = () => {
        navigate('/facility/notifications');
        setAnchorElNav(null);
    }

    const goToBirtdayPartySetup = () => {

        if (!isAdmin) {
            setAlertMessage({
                title: 'Not Authorized',
                message: 'You are not authorized to create birthday parties.'
            });
        } else {
            navigate('/facility/birthday-parties/setup');
            setAnchorElNav(null);
        }

    }

    const closeAlertMessage = () => {
        setAlertMessage(null)
    }

    const goToBirthdayPartyManageAll = () => {
        navigate('/facility/birthday-parties/manage-all');
        setAnchorElNav(null);
    }

    const goToBirthdayPartyBooking = () => {
        if (!hasReservationPrivileges) {
            setAlertMessage({
                title: 'Not Authorized',
                message: 'You are not authorized to create birthday parties.'
            });
        }  else {
            navigate(`/birthday-parties/${userData.facilityId}`);
            setAnchorElNav(null);
        }
    }

    const goToAreaManage = () => {
        navigate(`/facility/areas`);
        setAnchorElNav(null);
    }

    const goToAreaSetup = () => {

    }

    const toggleBirthdayParties = () => {
        setExpandBirthdayParties(!expandBirthdayParties);
    }

    const toggleManageBirthdayParties = () => {
        setExpandManagedBirthdayParties(!expandManageBirthdayParties);
    }

    const toggleAreas = () => {
        setExpandAreas(!expandAreas);
    }

    const [anchorElNav, setAnchorElNav] = useState(null);
    const open = Boolean(anchorElNav);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (

        <AppBar position='fixed'>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box>
                        <Link className="logo-container" href='/'>
                            <Avatar alt="logo" src={userData && userData.facilityAccountInfo && userData.facilityAccountInfo.facilityLogoUrl ? userData.facilityAccountInfo.facilityLogoUrl : logo} />
                        </Link>
                    </Box>
                    {!userIsLoading && userData && userData.isFacilityUserAccount &&

                        <Typography
                            variant="h6"
                            component="a"
                            sx={{
                                fontSize: {xs: '2.25vw', sm: '2.25vw', md: '1.25vw'},
                                mr: 2,
                                ml: 5,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'Helvetica',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'white',
                                textDecoration: 'none',
                                flexGrow: 1
                            }}
                        >
                            {userData.organizationName.toUpperCase()}
                        </Typography>
                    }

                    {
                        userData && userData.isFacilityUserAccount &&
                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" ml={5} mr={5}>
                            <IconButton onClick={handleOpenNavMenu}>
                                <AppsOutlinedIcon sx={{ color: 'white' }} />
                            </IconButton>
                            <Popover
                                id="basic-menu"
                                anchorEl={anchorElNav}
                                open={open}
                                onClose={handleCloseNavMenu}
                            >
                                <List>
                                    <ListItemButton sx={{ color: '#14254C' }} onClick={toggleAreas}>
                                        <ListItemIcon>
                                            <SpaceDashboardOutlinedIcon></SpaceDashboardOutlinedIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Areas" />
                                        {expandAreas ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={expandAreas} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4, color: '#14254C' }} onClick={goToAreaManage}>
                                                <ListItemIcon>
                                                    <ManageSearchOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Manage" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                    <ListItemButton sx={{ color: '#14254C' }} onClick={goToFacilityCalendar}>
                                        <ListItemIcon>
                                            <CalendarMonthOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Facility Calendar" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ color: '#14254C' }} onClick={goToCustomers}>
                                        <ListItemIcon>
                                            <PeopleAltOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Customers" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ color: '#14254C' }} onClick={toggleBirthdayParties}>
                                        <ListItemIcon>
                                            <CakeOutlinedIcon></CakeOutlinedIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Birthday Parties" />
                                        {expandBirthdayParties ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={expandBirthdayParties} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4, color: '#14254C' }} onClick={goToBirtdayPartySetup}>
                                                <ListItemIcon>
                                                    <AddBoxOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Create" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4, color: '#14254C' }} onClick={goToBirthdayPartyManageAll}>
                                                <ListItemIcon>
                                                    <BuildCircleOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Manage / Package" />
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4, color: '#14254C' }} onClick={goToBirthdayPartyBooking}>
                                                <ListItemIcon>
                                                    <BookmarkAddOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Book A Party" />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                    <ListItemButton sx={{ color: '#14254C' }} onClick={goToFacilitySettings}>
                                        <ListItemIcon>
                                            <SettingsOutlinedIcon></SettingsOutlinedIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Settings" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ color: '#14254C' }} onClick={goToNotificationSettings}>
                                        <ListItemIcon>
                                            <ChatBubbleOutlineOutlinedIcon></ChatBubbleOutlineOutlinedIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Communications" />
                                    </ListItemButton>
                                </List>
                            </Popover>
                        </Box>
                    }

                    {
                        !userIsLoading &&
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            {currentUser && userData &&
                                <Fragment>
                                    <Typography textAlign={'right'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'white'} variant="subtitle1" component="div">
                                        {userData.userEmailAddress}
                                    </Typography>
                                    <Typography textAlign={'right'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'white'} variant="subtitle2" component="div">
                                        {userData.userPermission}
                                    </Typography>
                                    <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                        <Button size='small' sx={{ color: 'white' }} onClick={signOut}>
                                            Sign Out
                                        </Button>
                                    </Box>
                                </Fragment>
                            }
                            {
                                !currentUser &&
                                <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                    <Button size='small' sx={{ color: 'white' }} onClick={signIn}>
                                        Sign In
                                    </Button>
                                </Box>
                            }
                        </Box>
                    }

                </Toolbar>
            </Container>
        </AppBar>
    )

}

export default NavigationFacility