import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, child, set, update } from "firebase/database";

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
  };

  // Initialize Firebase
initializeApp(firebaseConfig);

export const saveReservationEdits = async (facilityId, customerId, reservationDate, reservationId, reservationNotes, reservationTitle, reservationSport) => {

    try {
        const dbRef = ref(getDatabase());

        const updates = {};
        if (reservationNotes) {
            updates[`notes`] = reservationNotes && reservationNotes.length > 0 ? reservationNotes : null;
        }

        if (reservationTitle) {
            updates[`title`] = reservationTitle && reservationTitle.length > 0 ? reservationTitle : null;
        }

        if (reservationSport) {
            updates[`reservedForSport`] = reservationSport;
        }

        if (customerId) {
            const customerReservationPath = `customer-user-accounts/${customerId}/reservations/${reservationDate}/${reservationId}`;
            const customerRef = ref(getDatabase(), customerReservationPath);
            const customerReservationSnap = await get(child(dbRef, customerReservationPath));
            if (!customerReservationSnap.exists()){
                return false;
            }
            await update(customerRef, updates);
        }

        const facilityReservationPath = `facility-user-accounts/${facilityId}/reservations/${reservationDate}/${reservationId}`;
        const facilityRef = ref(getDatabase(), facilityReservationPath);
        const facilityReservationSnap = await get(child(dbRef, facilityReservationPath));
        if (!facilityReservationSnap.exists()){
            return false;
        }

        await update(facilityRef, updates);

    } catch (error){
        console.log(error);
        return false;
    }
    return true;
}

export const setReservationPaidStatus = async (reservation, newPaidStatus) => {
    let updated = false;
    try {
        const dbRef = ref(getDatabase());
        const facilityReservationPath = `facility-user-accounts/${reservation.facilityId}/reservations/${reservation.dateKey}/${reservation.reservationId}`;
        const customerReservationPath = `customer-user-accounts/${reservation.customerId}/reservations/${reservation.dateKey}/${reservation.reservationId}`;

        const facilityReservationSnap = await get(child(dbRef, facilityReservationPath));
        if (facilityReservationSnap.exists()){
            const facilityDbRef = ref(getDatabase(), `${facilityReservationPath}/paidOrUnpaid`);
            set(facilityDbRef, newPaidStatus);
            updated = true;
        }

        const customerReservationSnap = await get(child(dbRef, customerReservationPath));
        if (customerReservationSnap.exists()){
            const customerDbRef = ref(getDatabase(), `${customerReservationPath}/paidOrUnpaid`);
            set(customerDbRef, newPaidStatus);
            updated = true;
        }

    } catch (error){
        console.log(error);
    }
    return updated;
}