import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/material';

const MembershipList = ({ memberships, selectMembership, removeMembership, closeList, goUp, isEditable = false, header = 'Memberships', showHeader = true }) => {

    const add = (e) => {
        selectMembership();
    }

    const remove = (e) => {
        removeMembership(e.currentTarget.dataset.id);
    }

    let bottom = '1';
    if (goUp) {
        bottom = '0%';
    }

    return (
        <Box>
            {
                showHeader &&
                <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                    {header}
                </Typography>
            }

            <Stack>
                <Stack direction={'row'} overflow={'hidden'} flexWrap={'wrap'} rowGap={1} columnGap={1} >
                    {

                        memberships.map(membership => (
                            <Box display={'flex'}>
                                <Stack key={membership.facilityMembershipId} ml={2} spacing={1} direction={'row'} padding={'0.25vw'} alignItems={'center'} border={'solid'} borderRadius={'5px'} borderColor={'#DEDEDE'}>
                                    <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '1.0vw' } }}>{membership.name}</Typography>
                                    {
                                        isEditable &&
                                        <Button variant='contained' sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '0.75vw' } }} aria-label="remove-membership" onClick={remove.bind(this)} data-id={membership.facilityMembershipId}>
                                            Remove
                                        </Button>
                                    }
                                </Stack>
                            </Box>
                        ))
                    }
                </Stack>
                {isEditable &&
                    <Button sx={{ fontWeight: 'bold' }} onClick={add.bind(this)} data-id={'membership-add'}>Add Membership</Button>
                }
            </Stack>


            {/* <List>
                {
                    memberships.map(membership => (
                        <ListItem key={membership.facilityMembershipId} divider={true} data-id={membership.facilityMembershipId}>
                            <ListItemText sx={{ my: 0, ml: 1 }} primary={membership.name} primaryTypographyProps={{
                                fontWeight: '500',
                                letterSpacing: 0,
                                color: '#14254C'
                            }} ></ListItemText>
                            <Button hidden={!isEditable} aria-label="remove-membership" onClick={remove.bind(this)}>
                                Remove
                            </Button>
                        </ListItem>
                    ))
                }
                {
                    <ListItem key={'membership-add-key'} divider={false} onClick={add.bind(this)} data-id={'membership-add'}>
                        <ListItemText sx={{ my: 0, ml: 1 }} primary={`Add Membership`} primaryTypographyProps={{
                            fontWeight: 'bold',
                            letterSpacing: 0,
                        }} ></ListItemText>
                    </ListItem>
                }
            </List> */}
        </Box>
    )

}

export default MembershipList;