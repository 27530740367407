import SearchResultCard from "./search-result-card/search-result-card.component";
import { SearchForReservationResultsContainer } from './search-for-reservations-results.styles'
import { useContext, useEffect, useState } from "react";
import { SearchForReservationContext } from '../../../contexts/search-for-reservation/search-for-reservation.context';
import { useNavigate } from "react-router-dom";
import { Button, Box, Stack, Typography, Divider, Popover, Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SearchForReservationBookingPreview from '../search-for-reservation-booking-preview/search-for-reservation-booking-preview.component';


const SearchForReservationResults = () => {

    const { reservationSearchData, searchResults, searchResultsToBook, clearSearchResultsToBook, previewIsShowing, backToSearchUrl, setPreviewIsShowing } = useContext(SearchForReservationContext);
    const displayMultipleDates = searchResults && Object.keys(searchResults).length === 1 ? false : true;
    const displayMultipleSpaces = reservationSearchData.numberOfSpaces > 1;

    const [cartAnchorEl, setCartAnchorEl] = useState(null);

    useEffect(() => {

        window.scrollTo(0, 0);

        setCartAnchorEl(null);
        clearSearchResultsToBook();
    }, []);

    const navigate = useNavigate();

    const closeReservationPreview = () => {
        setCartAnchorEl(null);
    }

    const showReservationPreview = (event) => {
        if (!cartAnchorEl) {
            setCartAnchorEl(event.currentTarget);
        } else {
            setCartAnchorEl(null);
        }

    }

    const backToReservationSearch = () => {
        if (backToSearchUrl) {
            navigate(backToSearchUrl);
        } else {
            navigate('/');
        }
    }

    const performAction = () => {
        navigate('/bookings/booking-summary');
    }
    const cartIsOpen = Boolean(cartAnchorEl);

    return (
        <SearchForReservationResultsContainer $justifycontent={'center'} $overflow={displayMultipleDates ? 'scroll' : 'visible'} $resultswrap={'wrap'}>
            {/* <Drawer open={previewIsShowing} onClose={closeReservationPreview} anchor="right"> */}
            <Popover
                open={cartIsOpen}
                anchorEl={cartAnchorEl}
                onClose={closeReservationPreview}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <SearchForReservationBookingPreview></SearchForReservationBookingPreview>
            </Popover>
            {/* </Drawer> */}
            <Stack mb={2} ml={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Button sx={{ fontSize: { xs: '2.5vw', sm: "2.5vw", md: '1.0vw' } }} onClick={backToReservationSearch} startIcon={<ArrowBackIcon />}>
                    Back To Search
                </Button>
                <Box bgcolor={'blue'} sx={{ backgroundColor: 'blue' }}></Box>
                {(displayMultipleDates || displayMultipleSpaces) &&
                    <Fab
                        variant="extended" color="primary"
                        sx={{
                            position: 'fixed',
                            top: '10%',
                            right: '2%',
                            marginTop: '0.25vh'
                        }}
                    >
                        <Box display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end">
                            <Button sx={{ color: 'white', fontSize: { xs: '2.5vw', sm: "2.5vw", md: '1.0vw' } }} onClick={showReservationPreview} startIcon={<ShoppingCartCheckoutIcon />}>
                                {`Reservation Cart(${searchResultsToBook.length})`}
                            </Button>
                            {
                                searchResultsToBook.length > 0 &&
                                <Button sx={{ fontWeight: 'bold', color: 'white' }} size="large" onClick={performAction}>
                                    BOOK
                                </Button>
                            }
                        </Box>
                    </Fab>
                }
            </Stack>
            <Stack mb={{ xs: 10, md: 0 }} ml={2} mr={1} spacing={2} direction={'row'} display={'flex'} alignItems={'center'} >
                {/* <Stack>
                    <Typography mt={1} ml={1} noWrap fontWeight={'bold'} color={"gray"} component="div" fontSize={{ xs: '4.0vw', sm: '4.0vw', md: '1.5vw', lg: '1.25vw', xl: '1.25vw' }}>
                        Filter Results
                    </Typography>
                    <Divider></Divider>
                </Stack> */}
                <Stack spacing={1} width={'100%'} >
                    {
                        searchResults && Object.keys(searchResults).map((dateKey) => {
                            return <Stack key={dateKey} spacing={1} display={'flex'} >
                                <Typography key={`${dateKey}-header`} ml={1} fontWeight={'bold'} mt={2} gutterBottom color={"gray"} component="div" fontSize={{ xs: '4.0vw', sm: '4.0vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' }}>
                                    {dateKey}
                                </Typography>
                                <Box display={'flex'} sx={{ marginLeft: '1.0vw' }}>
                                    <Stack ml={1} width={'100%'} key={`${dateKey}-results`} spacing={1} direction={{ xs: 'column', sm: 'column', md: 'row' }}  columnGap={2} flexWrap={'wrap'} rowGap={{ xs: 1, sm: 1, md: 3 }} alignItems={{ xs: 'center', sm: 'center', md: 'stretch' }} justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start' }} >
                                        {
                                            searchResults[dateKey].map((result) => {
                                                return <SearchResultCard key={`search-result-card-${result.placeHolderId ? result.placeHolderId : result.reservationId}`} searchResult={result} bookSingleSpace={!displayMultipleDates && !displayMultipleSpaces}></SearchResultCard>
                                            })
                                        }
                                    </Stack>
                                </Box>
                                <Divider></Divider>
                            </Stack>

                        })
                    }
                </Stack>
            </Stack>
        </SearchForReservationResultsContainer>
    )

}

export default SearchForReservationResults;