import { FacilityMembershipsPickerContainer, FacilityMembershipsPickerListContainer } from "./facility-memberships-picker.styles";
import FormHeader from "../../form-header/form-header.component";
import MembershipsListAll from "../../memberships-list-all/memberships-list-all.component";

import { Box, Stack, Button } from "@mui/material";


const FacilityMembershipsPicker = ({ closeView, selectMembership, selectedMemberships, selectAll, clearAll }) => {

    const closeFacilityMemberships = () => {
        closeView();
    }

    const selectAllMemberships = () => {
        selectAll();
    }

    const clearSelections = () => {
        clearAll();
    }
    return (
        <FacilityMembershipsPickerContainer>
            <FacilityMembershipsPickerListContainer>
                <FormHeader title={'Facility Memberships'}></FormHeader>
                <div className='membership-list-container'>
                    <MembershipsListAll selectMembership={selectMembership} selectedMemberships={selectedMemberships}></MembershipsListAll>
                </div>
                <Box display={'flex'} justifyContent={'center'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Button onClick={closeFacilityMemberships}sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '0.75vw' } }}>Done</Button>
                        <Button variant="contained" sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '0.75vw' } }} onClick={selectAllMemberships}>Select All</Button>
                        <Button variant="outlined" sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.5vw', lg: '1.0vw', xl: '0.75vw' } }} onClick={clearSelections}>Clear All</Button>
                    </Stack>
                </Box>
            </FacilityMembershipsPickerListContainer>
        </FacilityMembershipsPickerContainer>

    )

}

export default FacilityMembershipsPicker