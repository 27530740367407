import { useState } from 'react';
import { Stack, Popover, Typography, Box, IconButton, Button, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { CancelOutlined, SaveOutlined } from '@mui/icons-material';

const ViewReservationHeader = ({ isEditing, title, reservation, closeAction, actionSelected, editReservationActions, cancelReservationActions, optionsReservationActions, isBlackout = false }) => {

    const [editMenuAnchorEl, setEditMenuAnchorEl] = useState(null);
    const [cancelMenuAnchorEl, setCancelMenuAnchorEl] = useState(null);
    const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState(null);

    const openEditMenu = (event) => {
        setEditMenuAnchorEl(event.currentTarget);
    }
    const openCancelMenu = (event) => {
        setCancelMenuAnchorEl(event.currentTarget);
    }
    const openOptionsMenu = (event) => {
        setOptionsMenuAnchorEl(event.currentTarget);
    }

    const selectAction = (e) => {

        const action = e.currentTarget.dataset.id;
        actionSelected(action);

        if (editMenuAnchorEl) {
            closeEditMenu();
        }

        if (optionsMenuAnchorEl) {
            closeOptionsMenu();
        }

        if (cancelMenuAnchorEl) {
            closeCancelMenu();
        }

    }

    const saveAction = () => {
        actionSelected('save');
    }

    const cancelAction = () => {
        actionSelected('cancel');
    }

    const closeEditMenu = () => {
        setEditMenuAnchorEl(null);
    }

    const closeCancelMenu = () => {
        setCancelMenuAnchorEl(null);
    }

    const closeOptionsMenu = () => {
        setOptionsMenuAnchorEl(null);
    }

    const editMenuIsOpen = Boolean(editMenuAnchorEl);
    const optionsMenuIsOpen = Boolean(optionsMenuAnchorEl);
    const cancelMenuIsOpen = Boolean(cancelMenuAnchorEl);
    return (
        <Stack ml={2} mt={1} mr={2} direction={'row'} display={'flex'} justifyContent={'space-between'}>
            <Popover
                id={'search-for-birthday-popover'}
                open={editMenuIsOpen}
                anchorEl={editMenuAnchorEl}
                onClose={closeEditMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <Typography sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }} mt={1} ml={1} mr={1} mb={1}>{isBlackout ? "Options" : "Reservation Options"}</Typography>
                    <Divider variant='middle'></Divider>
                    <List sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }} key={'view-reservation-edit-menu'}>
                        {
                            editReservationActions.map((action) => {
                                return <ListItemButton divider={true} key={action} onClick={selectAction.bind(this)} data-id={action}>
                                    <ListItemText key={`${action}-list-tem`} primary={`${action}`} primaryTypographyProps={{
                                        letterSpacing: 0,
                                        color: '#14254C',
                                        fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' }
                                    }}></ListItemText>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Box>
            </Popover>
            <Popover
                id={'search-for-birthday-popover'}
                open={cancelMenuIsOpen}
                anchorEl={cancelMenuAnchorEl}
                onClose={closeCancelMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <Typography sx={{ fontSize: { xs: '1.25vw', sm: '1.0vw', md: '1.0vw', lg: '1.25vw', xl: '1.25vw' } }} mt={1} ml={1} mb={1}>Cancel Options</Typography>
                    <Divider variant='middle'></Divider>
                    <List sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }} key={'view-reservation-cancel-menu'}>
                        {
                            cancelReservationActions.map((action) => {
                                return <ListItemButton divider={true} key={action} onClick={selectAction.bind(this)} data-id={action}>
                                    <ListItemText key={`${action}-list-tem`} primary={`${action}`} primaryTypographyProps={{
                                        letterSpacing: 0,
                                        color: '#14254C',
                                        fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' }
                                    }}></ListItemText>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Box>
            </Popover>
            <Popover
                id={'search-for-birthday-popover'}
                open={optionsMenuIsOpen}
                anchorEl={optionsMenuAnchorEl}
                onClose={closeOptionsMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box>
                    <List key={'view-reservation-cancel-menu'}>
                        {
                            optionsReservationActions && optionsReservationActions.map((action) => {
                                return <ListItemButton divider={true} key={action} onClick={selectAction.bind(this)} data-id={action}>
                                    <ListItemText key={`${action}-list-tem`} primary={`${action}`} primaryTypographyProps={{
                                        letterSpacing: 0,
                                        color: '#14254C',
                                        fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' }
                                    }}></ListItemText>
                                </ListItemButton>
                            })
                        }
                    </List>
                </Box>
            </Popover>
            <Typography fontWeight={'bold'} ml={1} mt={2} textAlign={'left'} gutterBottom color={"gray"} sx={{ fontSize: { xs: '3.5vw', sm: '2.25vw', md: '1.5vw', lg: '1.5vw', xl: '1.5vw' } }}>
                {title}
            </Typography>
            <Stack mt={1} mb={1} spacing={1} direction={'row'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                {
                    editReservationActions && editReservationActions.length > 0 &&
                    <IconButton
                        aria-label="openEditMenu"
                        onClick={openEditMenu}
                    >
                        <EditOutlinedIcon></EditOutlinedIcon>
                    </IconButton>
                }
                {
                    cancelReservationActions && cancelReservationActions.length > 0 &&
                    <IconButton
                        aria-label="openCancelMenu"
                        onClick={openCancelMenu}
                    >
                        <DeleteOutlinedIcon></DeleteOutlinedIcon>
                    </IconButton>
                }

                {optionsReservationActions && optionsReservationActions.length > 0 &&
                    <IconButton
                        aria-label="openOptionsMenu"
                        onClick={openOptionsMenu}
                    >
                        <MoreVertOutlinedIcon></MoreVertOutlinedIcon>
                    </IconButton>
                }
                {
                    isEditing &&
                    <Button sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.25vw', lg: '1.0vw', xl: '0.75vw' } }} variant='outlined' startIcon={<CancelOutlined></CancelOutlined>} onClick={cancelAction}>
                        Cancel
                    </Button>
                }
                {
                    isEditing &&
                    <Button sx={{ fontSize: { xs: '2.25vw', sm: '2.25vw', md: '1.25vw', lg: '1.0vw', xl: '0.75vw' } }} variant='contained' startIcon={<SaveOutlined></SaveOutlined>} onClick={saveAction}>
                        Save
                    </Button>
                }
                {
                    !isEditing &&
                    <IconButton
                        size="medium"
                        aria-label="close"
                        onClick={closeAction}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                }

            </Stack>

        </Stack>
    )
}

export default ViewReservationHeader